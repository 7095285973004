
import { Equipment } from "@/types/equipment";
import { Options, Vue } from "vue-class-component";
import CircularStats from "./CircularStats.vue";
import HoursStats from "./HoursStats.vue";
import { mapActions, mapGetters } from "vuex";
import {
  EquipmentStatus,
  EquipmentStatuses,
} from "../../types/equipment-status";
import _ from "lodash";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { StatusEventEnum } from "../../types/status-events";

const moment = extendMoment(Moment);

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  components: {
    HoursStats,
    CircularStats,
  },
  methods: {
    ...mapActions(["calculateReliability", "calculateHoursByStatus"]),
  },
  computed: {
    ...mapGetters(["getEquipmentDetails", "getReliability"]),
  },
  name: "KPIsCard",
})
export default class KPIsCard extends Vue {
  getEquipmentDetails!: Equipment;
  fromDate = new Date();
  toDate = new Date();
  totalHours = 0;
  unschHours = 0;
  schHours = 0;
  runHours = 0;
  runLimitedHours = 0;
  abnrHours = 0;
  hoursForStatus = 0;
  equipmentStatus = EquipmentStatuses;
  nbrTrips = 0;
  utilization = 0;
  allEventsTotalHours = 0;
  mounted(): void {
    this.fromDate = new Date(this.getInitialDateRange().min);
    this.toDate = new Date(this.getInitialDateRange().max);
  }

  calculateHoursByStatus(): void {
    const sortedStatuses = _.sortBy(
      this.getEquipmentDetails?.equipmentStatuses,
      "date"
    );

    const filteredStatuses = _.filter(
      sortedStatuses,
      (status: EquipmentStatus, index: number) => {
        const fromDateMoment = moment(this.fromDate);
        const toDateMoment = moment(this.toDate);
        return (
          (status.event !==
            "shutdown_environment_external_process_external_event" &&
            moment(status.date).isSameOrAfter(fromDateMoment) &&
            moment(status.date).isBefore(toDateMoment)) ||
          (moment(status.date).isSameOrBefore(fromDateMoment) &&
            moment(sortedStatuses[index + 1]?.date).isAfter(fromDateMoment))
        );
      }
    );

    const hoursByStatuses = _.reduce(
      filteredStatuses,
      (acc: any, { status, date }, index) => {
        if (moment(date).isBefore(moment(this.fromDate))) {
          date = moment(this.fromDate).format();
        }
        const nextStatus = filteredStatuses[index + 1];
        if (!acc[status]) acc[status] = 0;
        if (nextStatus) {
          acc[status] +=
            +moment.range(moment(date), moment(nextStatus.date)) / 3600000;
        } else {
          acc[status] +=
            +moment.range(moment(date), moment(this.toDate)) / 3600000;
        }
        return acc;
      },
      {}
    );

    this.totalHours =
      +moment.range(moment(this.fromDate), moment(this.toDate)) / 3600000;
    this.unschHours = hoursByStatuses[EquipmentStatuses.UNSCH] ?? 0;
    this.schHours = hoursByStatuses[EquipmentStatuses.SCH] ?? 0;
    this.runHours = hoursByStatuses[EquipmentStatuses.RUN] ?? 0;
    this.runLimitedHours = hoursByStatuses[EquipmentStatuses.RUN_LIMITED] ?? 0;
    this.abnrHours = hoursByStatuses[EquipmentStatuses.ABNR] ?? 0;
    this.nbrTrips = filteredStatuses.filter(
      (status) =>
        status.status == EquipmentStatuses.UNSCH &&
        status.event == StatusEventEnum.UNSCH_FAILURE
    ).length;
    this.allEventsTotalHours =
      this.runHours +
      this.runLimitedHours +
      this.abnrHours +
      this.unschHours +
      this.schHours;
    if (this.allEventsTotalHours === 0) {
      this.utilization = 0;
    } else {
      this.utilization =
        100 *
        (((hoursByStatuses[EquipmentStatuses.RUN] ?? 0) +
          (hoursByStatuses[EquipmentStatuses.RUN_LIMITED] ?? 0)) /
          this.allEventsTotalHours);
    }
  }

  get reliability(): number {
    this.calculateHoursByStatus();
    let relaibailityDecimalValue = (
      (100 * (this.totalHours - this.unschHours)) /
      this.totalHours
    ).toFixed(1);
    return parseFloat(relaibailityDecimalValue);
  }

  get availability(): number {
    this.calculateHoursByStatus();
    let availabilityDecimalValue = (
      (100 * (this.totalHours - this.schHours - this.unschHours)) /
      this.totalHours
    ).toFixed(1);
    return parseFloat(availabilityDecimalValue);
  }

  get unschHoursTotal(): number {
    this.calculateHoursByStatus();
    return this.unschHours;
  }

  get schHoursTotal(): number {
    this.calculateHoursByStatus();
    return this.schHours;
  }

  get runHoursTotal(): number {
    this.calculateHoursByStatus();
    return this.runHours;
  }

  get runLimitedHoursTotal(): number {
    this.calculateHoursByStatus();
    return this.runLimitedHours;
  }

  get abnrHoursTotal(): number {
    this.calculateHoursByStatus();
    return this.abnrHours;
  }

  get nbrTripsTotal(): number {
    this.calculateHoursByStatus();
    return this.nbrTrips;
  }
  get utilizationPercentage(): number {
    this.calculateHoursByStatus();
    return parseFloat(this.utilization.toFixed(1));
  }

  getInitialDateRange(): any {
    const toDate = moment();
    const fromDate = moment().subtract(12, "months");
    return {
      min: fromDate,
      max: toDate,
    };
  }
}
