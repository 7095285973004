
import { Options, Vue } from "vue-class-component";
import { AgGridVue } from "ag-grid-vue3";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import PageTitle, { PageEnum } from "../common/PageTitle.vue";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { AutomaticStatusType } from "@/types/automatic-status";
import moment, { MomentInput } from "moment";
import HistoricalStatusDialog from "../equipment-details/HistoricalStatusDialog.vue";
import { EquipmentStatus, EquipmentStatuses } from "@/types/equipment-status";
import { Equipment } from "../../types/equipment";
import {
  formatDate,
  convertEnumStatus,
} from "../../helpers/equipment-status-helpers";
import { EquipmentComment } from "@/types/equipment-comment";
import EquipmentSelectorFilter from "../equipment-list/EquipmentSelectorFilter.vue";
import StatusSelectorFilter from "../equipment-list/StatusSelectorFilter.vue";
@Options({
  components: {
    AgGridVue,
    PageTitle,
    HistoricalStatusDialog,
    ConfirmDialog,
    EquipmentSelectorFilter,
    StatusSelectorFilter,
  },
  props: {
    equipmentModelName: {
      type: String,
    },
    equipmentTag: {
      type: String,
    },

    props: {
      title: {
        type: String,
        required: true,
      },
      page: {
        type: Number,
        required: true,
      },
    },
  },
  methods: {
    ...mapActions([
      "loadAutomaticStatus",
      "displayHistoricalStatusDialog",
      "equipmentDetailsPageLoaded",
      "deleteEquipmentStatus",
      "modifyEquipmentStatus",
      "confirmSelectedStatus",
      "updateEquipmentComment",
      "equipmentDetailsReset",
      "currentSelectedEquipmentReset",
      "equipmentListLoaded",
    ]),
    formatDate(date: MomentInput) {
      return moment(date).format("YYYY/MM/DD HH:mm");
    },
    updateSuccess() {
      this.loadAutomaticStatus();
    },
  },
  computed: {
    ...mapGetters([
      "getautomaticStatusData",
      "getEquipmentDetails",
      "getDateToUpdate",
    ]),
  },
  watch: {
    equipment: function (val, oldVal) {
      if (oldVal?.id !== val?.id) {
        this.selectedStatus = undefined;
      }
    },
  },
  name: "AutomaticStatus",

  data() {
    return {
      selectedStatus: undefined,
      selected: [],
    };
  },
})
export default class AutomaticStatus extends Vue {
  equipmentListLoaded!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  equipmentDetailsPageLoaded!: ({
    equipmentModelName,
    equipmentTag,
  }: any) => Promise<void>;
  equipmentModelName?: string = undefined;
  equipmentTag?: string = undefined;
  displayHistoricalStatusDialog!: ({
    displayHistoricalStatusDialog,
    isEditMode,
  }: {
    displayHistoricalStatusDialog: boolean;
    isEditMode: boolean;
  }) => void;
  isEditMode = false;
  pageEnum = PageEnum;
  selected: any[] = [];
  getautomaticStatusData!: AutomaticStatusType;
  loadAutomaticStatus!: () => Promise<void>;
  selectedStatus: EquipmentStatus | undefined = undefined;
  status!: EquipmentStatus;
  getEquipmentDetails!: Equipment;
  displayConfirmDialog = false;
  confirmPopUpTitle = "Status update";
  deleteEquipmentStatus!: (statusId: number) => Promise<boolean>;
  equipmentDetailsFound: any;
  updatedEquipModelName: any;
  currentEquipmentId = 0;
  updatedEquipmentTag: any;
  updateEquipmentComment!: ({
    equipmentId,
    equipmentCommentId,
    equipmentComment,
  }: {
    equipmentId: number;
    equipmentCommentId: number | undefined;
    equipmentComment: EquipmentComment | undefined;
  }) => Promise<EquipmentComment>;
  equipmentDetailsReset!: () => void;
  currentSelectedEquipmentReset!: () => void;

  columnsautomaticStatus = [
    {
      name: "date",
      label: "Date Time",
      align: "left",
      field: "date",
      sortable: true,
    },
    { name: "id", label: "Tag ID", align: "left", field: "id", sortable: true },
    {
      name: "status",
      label: "Automatic Status",
      align: "left",
      field: "status",
    },
    { name: "automaticStatusAction", label: "Action", align: "left" },
  ];

  pagination = {
    sortBy: "date",
    descending: true,
    rowsPerPage: 10,
  };

  modifyEquipmentStatus!: ({
    statusId,
    automaticStatus,
  }: {
    statusId: number | undefined;
    automaticStatus: boolean | undefined;
  }) => Promise<boolean>;

  confirmSelectedStatus!: ({
    statusId,
  }: {
    statusId: number | undefined;
  }) => Promise<boolean>;

  getDateToUpdate!: string;

  async mounted(): Promise<void> {
    this.$q.loading.show();
    await this.equipmentListLoaded(this.$route.params.site.toString());
    await this.loadAutomaticStatus();
    if (this.getautomaticStatusData) {
      this.$q.loading.hide();
    }
  }
  unmounted(): void {
    this.equipmentDetailsReset();
    this.currentSelectedEquipmentReset();
  }

  getSelectedString() {
    let rowSelected: any = this.getautomaticStatusData;
    return this.selected.length === 0
      ? ""
      : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } selected of ${rowSelected.length}`;
  }

  get currentSelectedStatus(): EquipmentStatus | undefined {
    return this.selectedStatus;
  }

  async confirmStatus(confirmEquipData: any): Promise<void> {
    const successUpdateEquipmentStatus = await this.modifyEquipmentStatus({
      statusId: confirmEquipData?.id,
      automaticStatus: true,
    });
    if (successUpdateEquipmentStatus) {
      this.showNotif();
      this.loadAutomaticStatus();
    }
  }

  showNotif(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } Status has been confirmed `,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  async onStatusBtnClick(equipData: any, isFlag: string): Promise<void> {
    this.$q.loading.show();
    if (equipData.equipment) {
      this.updatedEquipModelName = equipData.equipment.equipmentModel.name;
      this.updatedEquipmentTag =
        equipData.equipment.tag !== null ? equipData.equipment.tag : "";
    }
    if (equipData.equipment.id !== this.currentEquipmentId) {
      //to check if the equipment is changed
      if (
        this.updatedEquipModelName &&
        this.updatedEquipModelName !== null &&
        this.updatedEquipmentTag !== null &&
        this.updatedEquipmentTag !== undefined
      ) {
        await this.equipmentDetailsPageLoaded({
          equipmentModelName: this.updatedEquipModelName,
          equipmentTag: this.updatedEquipmentTag,
        });
      }
      this.currentEquipmentId = equipData.equipment.id;
    }
    this.$q.loading.hide();
    if (this.getEquipmentDetails) {
      if (isFlag === "delete") {
        this.displayConfirmDialog = true;
        this.selectedStatus = equipData;
      } else if (isFlag === "update") {
        this.getEquipmentUpdateData(equipData);
      } else {
        this.confirmStatus(equipData);
      }
    }
  }

  async onSelectedBtnClick(slectedRows: any) {
    this.$q.loading.show();
    let selectedStatusId = slectedRows.map((a: { id: any }) => a.id);
    const successConfirmSelectedStatus = await this.confirmSelectedStatus({
      statusId: selectedStatusId,
    });
    if (successConfirmSelectedStatus) {
      this.showSelectedStatusConfirmed();
      this.loadAutomaticStatus();
      this.selected = [];
    }
    this.$q.loading.hide();
  }

  showSelectedStatusConfirmed(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `The Selected Status has been confirmed `,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }

  getEquipmentUpdateData(data: any): void {
    if (data) {
      this.selectedStatus = _.find(
        this.getEquipmentDetails?.equipmentStatuses,
        (equipmentStatus: EquipmentStatus) => {
          return equipmentStatus.id === data.id;
        }
      );
    }
    this.activateUpdateStatus();
  }

  async activateUpdateStatus(): Promise<void> {
    await this.displayHistoricalStatusDialog({
      displayHistoricalStatusDialog: true,
      isEditMode: true,
    });
    this.loadAutomaticStatus();
  }

  get confirmDeleteMessage(): string {
    return (
      "Do you confirm the deletion of status " +
      convertEnumStatus(this.selectedStatus?.status) +
      " starting at " +
      formatDate(this.selectedStatus?.date) +
      "?"
    );
  }

  onClose(): void {
    this.displayConfirmDialog = false;
  }

  async onDelete(): Promise<void> {
    if (this.selectedStatus) {
      const isDeleteSuccess = await this.deleteEquipmentStatus(
        this.selectedStatus.id
      );
      this.onClose();
      if (isDeleteSuccess) await this.showStatusDeleted();
      this.loadAutomaticStatus();
    }
  }

  showStatusDeleted(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `Status for ${equipment.equipmentModel.name} ${equipment.tag} have been deleted.`,
      color: "positive",
      textColor: "black",
      actions: [{ icon: "close", color: "black" }],
    });
  }
}
