
import AddButton, { PopinType } from "./AddButton.vue";
import AddOrUpdateEquipmentLinkPopin from "./AddOrUpdateEquipmentLinkPopin.vue";
import { Options, Vue } from "vue-class-component";
import { EquipmentLink } from "../../types/equipment-link";
import { mapActions, mapGetters } from "vuex";
import EditMenu from "./EditMenu.vue";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { UserRole } from "../../types/user-info";
import { isUserUnknown, isUserGuest } from "../../helpers/user-role-helper";
import { Equipment } from "@/types/equipment";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions(["displayAddLinkPopin", "deleteEquipmentLink"]),
  },
  computed: {
    ...mapGetters(["getUserRole", "getEquipmentDetails"]),
  },
  components: {
    AddButton,
    AddOrUpdateEquipmentLinkPopin,
    EditMenu,
    ConfirmDialog,
  },
  data() {
    return {
      currentLink: undefined,
    };
  },
  name: "LinksCard",
})
export default class LinksCard extends Vue {
  popinTypeEnum = PopinType;
  currentLink!: EquipmentLink | undefined;
  isEditMode = false;
  displayAddLinkPopin!: (displayAddLinkPopin: boolean) => void;
  deleteEquipmentLink!: (equipmentLink: EquipmentLink) => void;
  displayConfirmDeleteDialog = false;
  getUserRole!: UserRole;
  getEquipmentDetails!: Equipment;

  onClickEditLink(link: EquipmentLink): void {
    this.currentLink = link;
    this.isEditMode = true;
    this.displayAddLinkPopin(true);
  }
  onClosePopin(): void {
    this.currentLink = undefined;
    this.isEditMode = false;
  }

  onDelete(link: EquipmentLink): void {
    this.currentLink = link;
    this.displayConfirmDeleteDialog = true;
  }

  onConfirmDelete(): void {
    if (this.currentLink) {
      this.deleteEquipmentLink(this.currentLink);
      this.onClose();
    }
  }

  showQuantumLink(): boolean {
    if (
      this.getEquipmentDetails.country === "Angola-B17" ||
      this.getEquipmentDetails.country === "Nigeria-DW" ||
      (this.getEquipmentDetails.country === "Congo" &&
        this.getEquipmentDetails.site === "Alima") ||
      (this.getEquipmentDetails.country === "Congo" &&
        this.getEquipmentDetails.site === "Moho Nord")
    )
      return true;
    return false;
  }

  getEquipmentTitle(): string {
    return this.getEquipmentDetails.equipmentTitle;
  }

  getAsset(): string {
    const site = this.getEquipmentDetails.site;
    if (site === "Alima") {
      return "MOHO_BILONDO";
    }
    if (site === "Moho Nord") {
      return "Moho_Nord";
    }
    return site.toLocaleUpperCase();
  }

  onClose(): void {
    this.displayConfirmDeleteDialog = false;
  }

  get confirmDeleteLinkMessage(): string {
    return "Do you confirm to delete the link " + this.currentLink?.title;
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }
}
