
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import moment, { MomentInput } from "moment";
import { ref } from "vue";
import AlertFeedbackSelector from "./AlertFeedbackSelector.vue";
import {
  EquipmentMlStatus,
  FeedbackEnum,
} from "../../types/equipment-ml-status";
import LinksCard from "../equipment-details/LinksCard.vue";
import { Equipment } from "../../types/equipment";
import { UserRole } from "../../types/user-info";
import {
  isUserUnknown,
  isUserGuest,
  isUserOffshore,
} from "../../helpers/user-role-helper";
import RealAlertPopin from "./RealAlertPopin.vue";
import { downloadFile } from "../../helpers/file-upload-helper";
import NormalBehaviorPopin from "./NormalBehaviorPopin.vue";
import { alertFilterOptions } from "../../helpers/equipment-alerting-helper";
import {
  isSuccess,
  getMessageByStatusCode,
  treatErrors,
} from "../../helpers/http-status-helper";
import EquipmentApi from "@/api/equipment";
import { formatDateWithoutHours } from "../../helpers/equipment-status-helpers";

@Options({
  computed: {
    ...mapGetters([
      "getEquipmentMlStatuses",
      "getEquipmentList",
      "getEquipmentDetails",
      "getUserRole",
      "getSasToken",
      "getSelectedSite",
      "getSelectedCountryBloc",
    ]),
  },
  methods: {
    ...mapActions([
      "equipmentAlertingPageLoaded",
      "updateEquipmentMlStatus",
      "updateEquipmentDetails",
      "equipmentDetailsReset",
      "displayRealAlertPopin",
      "claimSasToken",
      "displayNormalBehaviorPopin",
      "displayHttpErrorDialog",
    ]),
    formatDate(date: MomentInput) {
      return moment(date).format("YYYY/MM/DD - HH:mm");
    },
  },
  components: {
    AlertFeedbackSelector,
    LinksCard,
    RealAlertPopin,
    NormalBehaviorPopin,
  },
  data() {
    return {
      selectedAlertFilter: undefined,
      alertFilterOptions,
      equipmentMlStatusesClone: [],
    };
  },
  name: "AlertsCard",
})
export default class AlertsCard extends Vue {
  equipmentAlertingPageLoaded!: () => Promise<void>;
  getUserRole!: UserRole;
  getEquipmentMlStatuses!: EquipmentMlStatus[];
  equipmentMlStatusesClone!: EquipmentMlStatus[];
  getEquipmentList!: Equipment[];
  getSelectedSite!: string;
  getSelectedCountryBloc!: string;
  updateEquipmentDetails!: (equipment: Equipment | undefined) => void;
  equipmentDetailsReset!: () => void;
  getEquipmentDetails!: Equipment;
  updateEquipmentMlStatus!: ({
    equipmentId,
    sensorStatusId,
    unread,
    feedback,
  }: any) => Promise<boolean>;
  filter = ref("");
  selectedAlertFilter!: FeedbackEnum | undefined;
  displayRealAlertPopin!: (displayRealAlertPopin: boolean) => void;
  displayNormalBehaviorPopin!: (displayNormalBehaviorPopin: boolean) => void;
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;

  claimSasToken!: () => Promise<void>;
  getSasToken!: string;

  pagination = {
    sortBy: "date",
    descending: true,
    rowsPerPage: 10,
  };
  columns = [
    {
      name: "unread",
      align: "left",
      field: "unread",
    },
    {
      name: "date",
      label: "Date - Time (1st drift) ",
      align: "left",
      field: "date",
      sortable: true,
    },
    {
      name: "equipment",
      align: "left",
      label: "Equipment",
      field: "equipmentName",
    },
    {
      name: "type",
      align: "left",
      label: "Alert classification",
      field: "feedback",
    },
    {
      name: "downloadPlot",
      field: "plotPath",
    },
    {
      name: "expand",
      align: "left",
      label: "",
    },
  ];

  rowClass(unread: boolean): string {
    if (unread) return "text-bold";
    else return "";
  }
  async updateStatus(
    equipmentId: number,
    equipmentMlStatusId: number,
    unread: boolean,
    feedback: FeedbackEnum
  ): Promise<void> {
    this.updateEquipmentMlStatus({
      equipmentId,
      equipmentMlStatusId,
      unread,
      feedback,
    });
  }

  async mounted(): Promise<void> {
    this.$q.loading.show();
    await this.equipmentAlertingPageLoaded();
    this.onEquipmentNameClick(this.getEquipmentMlStatuses[0]);
    this.equipmentMlStatusesClone = this.getEquipmentMlStatuses;
    this.$q.loading.hide();
  }

  get currentSelectedEquipment(): Equipment | undefined {
    return this.getEquipmentDetails;
  }

  onEquipmentNameClick(equipmentMLStatus: EquipmentMlStatus): void {
    if (equipmentMLStatus) {
      this.updateEquipmentDetails(equipmentMLStatus.equipment);
    }
  }

  unmounted(): void {
    this.equipmentDetailsReset();
  }

  isUserUnknownOrGuestOrOffshore(): boolean {
    return isUserUnknown(this.getUserRole);
  }

  async downloadPlot(plotPath: string | undefined): Promise<void> {
    if (plotPath) {
      this.$q.loading.show();
      await this.claimSasToken();
      const token = this.getSasToken;
      if (token) {
        await downloadFile(token, "anomaly-detection-plots", plotPath);
      }
      this.$q.loading.hide();
    }
  }
  async onExportData(): Promise<void> {
    this.$q.loading.show();
    try {
      const result = await EquipmentApi.exportEquipmentAlertData(
        this.getSelectedCountryBloc,
        this.getSelectedSite
      );
      if (isSuccess(result.status)) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `OneCBM-Alert-Report-${this.getSelectedCountryBloc}-${
            this.getSelectedSite
          }-${formatDateWithoutHours(new Date())}.csv`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (err: any) {
      this.displayHttpErrorDialog({
        displayHttpErrorDialog: true,
        httpErrorMessage:
          "Export alert list : " + getMessageByStatusCode(treatErrors(err)),
      });
    }
    this.$q.loading.hide();
  }

  set filterByAlert(feedback: FeedbackEnum | undefined) {
    this.equipmentMlStatusesClone = this.getEquipmentMlStatuses;
    if (feedback === undefined) {
      this.equipmentMlStatusesClone = this.equipmentMlStatusesClone.filter(
        (equipmentMLStatus: EquipmentMlStatus) =>
          equipmentMLStatus.feedback !== FeedbackEnum.NORMAL_BEHAVIOR &&
          equipmentMLStatus.feedback !== FeedbackEnum.SPURIOUS &&
          equipmentMLStatus.feedback !== FeedbackEnum.REAL
      );
    } else if (feedback !== null) {
      this.equipmentMlStatusesClone = this.equipmentMlStatusesClone.filter(
        (equipmentMLStatus: EquipmentMlStatus) =>
          equipmentMLStatus.feedback === feedback
      );
    }

    this.selectedAlertFilter =
      feedback === undefined ? FeedbackEnum.Empty_Unknown : feedback;
  }

  get filterByAlert(): FeedbackEnum | undefined {
    return this.selectedAlertFilter;
  }
}
