import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-pt-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isUserUnknownOrGuestOrOnshore())
        ? (_openBlock(), _createBlock(_component_q_icon, {
            key: 0,
            name: "add",
            class: "cursor-pointer q-mr-xs",
            size: "18px",
            onClick: _ctx.addRow
          }, null, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      (!_ctx.params.data.isFreeRow)
        ? (_openBlock(), _createBlock(_component_q_icon, {
            key: 1,
            name: "o_visibility",
            class: "cursor-pointer q-mr-xs",
            size: "18px",
            onClick: _ctx.goToDetails
          }, null, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true),
      (!_ctx.isUserUnknownOrGuestOrOnshore())
        ? (_openBlock(), _createBlock(_component_q_icon, {
            key: 2,
            name: "delete",
            class: "cursor-pointer q-mr-xs",
            size: "18px",
            onClick: _ctx.removeRow
          }, null, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_component_ConfirmDialog, {
      title: "Confirm the deletion",
      message: _ctx.confirmMessage,
      open: _ctx.isConfirmDeleteOffshoreOpen,
      onOnClose: _ctx.onClose,
      onOnConfirm: _ctx.onConfirm
    }, null, 8 /* PROPS */, ["message", "open", "onOnClose", "onOnConfirm"])
  ]))
}