
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import AlertsIcon from "../equipment-alerting/AlertsIcon.vue";
import Avatar from "../user/Avatar.vue";
import router from "../../router";
import {
  AssignedCountryAndSite,
  SiteAndRole,
  UserRole,
} from "../../types/user-info";
import { isUserOffshore } from "../../helpers/user-role-helper";
import _ from "lodash";
import { EquipmentStatuses } from "@/types/equipment-status";

@Options({
  name: "Header",
  props: {
    account: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters([
      "getSelectedSite",
      "getAssignedCountriesAndSites",
      "getUserRole",
      "getSelectedCountryBloc",
      "getExpandedItems",
    ]),
  },
  methods: {
    ...mapActions([
      "updateSelectedSiteAndUserRole",
      "equipmentListLoaded",
      "updateFilters",
    ]),
  },
  components: {
    Avatar,
    AlertsIcon,
  },
})
export default class Header extends Vue {
  getSelectedSite!: string;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  equipmentListLoaded!: (site: string) => void;
  getUserRole!: UserRole;
  getAssignedCountriesAndSites!: AssignedCountryAndSite[];
  getSelectedCountryBloc!: string;
  getExpandedItems!: { [key: string]: boolean };
  updateFilters!: ({
    selectedStatusFilter,
    criticalCheckboxFilter,
    selectedDateFilter,
    selectedEquipmentFilter,
    selectedEquipmentAlertsFilter,
    selectedSectorFilter,
    statusCheckboxFilter,
  }: {
    selectedStatusFilter: EquipmentStatuses[] | undefined;
    criticalCheckboxFilter: boolean | undefined;
    selectedDateFilter: string | undefined;
    selectedEquipmentFilter: string | undefined;
    selectedEquipmentAlertsFilter: string[] | undefined;
    selectedSectorFilter: string[] | undefined;
    statusCheckboxFilter: boolean | undefined;
  }) => void;

  resetSite(): void {
    this.updateSelectedSiteAndUserRole("");
    this.$router.push({ name: "Home" });
  }

  get selectedSite(): string {
    return this.getSelectedSite;
  }

  set selectedSite(site: string) {
    this.$q.loading.show();
    for (let key in this.getExpandedItems) {
      delete this.getExpandedItems[key];
    }
    this.updateFilters({
      selectedStatusFilter: [],
      criticalCheckboxFilter: false,
      selectedDateFilter: undefined,
      selectedEquipmentFilter: undefined,
      selectedEquipmentAlertsFilter: [],
      selectedSectorFilter: [],
      statusCheckboxFilter: false,
    });
    router.push({
      name: "EquipmentList",
      params: { countryBloc: this.getSelectedCountryBloc, site },
    });
    this.updateSelectedSiteAndUserRole(site);
    this.equipmentListLoaded(site);
    this.$q.loading.hide();
  }

  get assignedSites(): any {
    const countryAndSites = _.find(
      this.getAssignedCountriesAndSites,
      (assignedCountryAndSites) => {
        return (
          assignedCountryAndSites.countryAndBloc === this.getSelectedCountryBloc
        );
      }
    );
    return countryAndSites
      ? _.map(countryAndSites.sitesAndRoles, (siteAndRole: SiteAndRole) => {
          return siteAndRole.site;
        })
      : [];
  }

  isUserOffshore(): boolean {
    return isUserOffshore(this.getUserRole);
  }

  getCountryBlock(): string {
    if (this.getSelectedCountryBloc === "Denmark-NS") {
      return "Denmark-DUC";
    }
    return this.getSelectedCountryBloc;
  }
}
