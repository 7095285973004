import { createStore, createLogger } from "vuex";
import type { Equipment } from "../types/equipment";
import EquipmentApi from "../api/equipment";
import MsGraphApi from "../api/msgraph";
import { EquipmentStatus } from "@/types/equipment-status";
import axios from "axios";
import { EquipmentLink } from "@/types/equipment-link";
import moment from "moment-timezone";
import StatisticsApi from "@/api/statistics";
import { EquipmentAndStatistics } from "@/types/equipment-statistics";
import _ from "lodash";
import { EquipmentComment } from "@/types/equipment-comment";
import { EquipmentCommentFile } from "@/types/equipment-comment-file";
import { EquipmentMlStatus } from "@/types/equipment-ml-status";
import {
  EquipmentCommentOffshore,
  EquipmentOffShore,
  QuickDashboardEquipment,
  EquipmentCommentListOffshore,
} from "@/types/quick-dashboard-equipment";
import {
  AssignedCountryAndSite,
  SiteAndRole,
  UserRole,
} from "@/types/user-info";
import { mapEquipmentConditionEnumToString } from "@/helpers/equipment-condition-helper";
import SparePartsApi from "@/api/spare-part";
import { SparePart } from "@/types/spare-part";
import {
  getMessageByStatusCode,
  isSuccess,
} from "@/helpers/http-status-helper";
import ProductApi from "@/api/product";
import { ProductReference } from "@/types/product-references";
import { EquipmentParamDetails } from "@/types/equipment-tree";

const defaultEquipmentList: Equipment[] = [];
const defaultEquipmentParam: EquipmentParamDetails = {
  id: null,
  stopCodes: [],
  tradeType: [],
  equipmentDetails: [],
};
const defaultEquipmentStatisticsList: EquipmentAndStatistics = {};
const defaultEquipmentDetails: any = null;
const defaultAutomaticStatusData: any = null;
const defaultSelectedStatusData: any = null;
const defaultEquipmentCommentForBarChart: EquipmentComment[] = [];
const defaultCurrentSelectedEquipment: any = null;
const statusToUpdate: any = null;
const dateToUpdate: any = null;
const reportingView: any = null;
const defaultSelectedEvent: any = null;
const defaultSelectedTrade: any = null;
const defaultEquipmentCommentDetails: any = null;
const defaultSelectedEquipment: any = null;
const defaultEquipmentMlStatuses: EquipmentMlStatus[] = [];
const defaultEquipmentMlStatus: EquipmentMlStatus[] = [];
const defaultSelectedStatusFilter: any = null;
const defaultSelectedDateFilter: any = null;
const defaultSelectedEquipmentFilter: any = null;
const defaultSelectedSectorFilter: any = null;
const defaultSelectedSite: any = null;
const defaultAssignedCountriesAndSites: [] = [];
const defaultQuickDashboardEquipments: QuickDashboardEquipment[] = [];
const defaultUserRole: any = UserRole.UNKNOWN;
const defaultSelectedEquipmentMlStatus: EquipmentMlStatus[] = [];
const defaultExpandedItems: any = {};
const defaultHttpErrorMessage = "";
const defaultSelectedCountryBloc: any = null;
const defaultSelectedEquipmentAlertsFilter: [] = [];
const defaultProductList: ProductReference[] = [];

export default createStore({
  state: {
    msalConfig: {
      auth: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
        authority: process.env.VUE_APP_AZURE_AD_AUTHORITY,
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    authInfoMSGraph: null,
    authInfoAPI: null,
    userPicture: null,
    userRole: defaultUserRole,
    equipmentList: defaultEquipmentList,
    equipmentListForWeeklyReport: defaultEquipmentList,
    equipmentParam: defaultEquipmentParam,
    equipmentStatisticsList: defaultEquipmentStatisticsList,
    equipmentDetails: defaultEquipmentDetails,
    automaticStatusData: defaultAutomaticStatusData,
    selectedStatusData: defaultSelectedStatusData,
    equipmentCommentForBarChart: defaultEquipmentCommentForBarChart,
    displayAddLinkPopin: false,
    displayAddOilAnalysisPopin: false,
    displayAddVibrationAnalysisPopin: false,
    sasToken: null,
    displayAddLastStatusDialog: false,
    statusToUpdate: statusToUpdate,
    dateToUpdate: dateToUpdate,
    displayStatusTimeline: false,
    reportingView: reportingView,
    equipmentMlStatuses: defaultEquipmentMlStatuses,
    equipmentMlStatus: defaultEquipmentMlStatus,
    unreadAlertsTotal: 0,
    displayAddCommentPopin: false,
    displayHistoricalStatusDialog: false,
    selectedStatusEvent: defaultSelectedEvent,
    selectedTrade: defaultSelectedTrade,
    selectedEquipment: defaultSelectedEquipment,
    displayEditCommentPopin: false,
    equipmentCommentDetails: defaultEquipmentCommentDetails,
    selectedStatusFilter: defaultSelectedStatusFilter,
    criticalCheckboxFilter: false,
    statusCheckboxFilter: false,
    selectedDateFilter: defaultSelectedDateFilter,
    selectedEquipmentFilter: defaultSelectedEquipmentFilter,
    selectedSectorFilter: defaultSelectedSectorFilter,
    equipmentListFiltered: defaultEquipmentList,
    selectedCountryBloc: defaultSelectedCountryBloc,
    selectedCountryBlocInShort: defaultSelectedCountryBloc,
    selectedSite: defaultSelectedSite,
    currentSelectedEquipment: defaultCurrentSelectedEquipment,
    assignedCountriesAndSites: defaultAssignedCountriesAndSites,
    isCommentPopinReadOnly: false,
    isEditModeHistoricalStatusDialog: false,
    quickDashboardEquipments: defaultQuickDashboardEquipments,
    displayRealAlertPopin: false,
    selectedEquipmentMlStatus: defaultSelectedEquipmentMlStatus,
    expandedItems: defaultExpandedItems,
    areAllExpanded: false,
    displayHttpErrorDialog: false,
    httpErrorMessage: defaultHttpErrorMessage,
    displayAddSparePartDialog: false,
    selectedEquipmentAlertsFilter: defaultSelectedEquipmentAlertsFilter,
    displayNormalBehaviorPopin: false,
    displayAddOrUpdateProductReferencePopin: false,
    displayEquipmentCommentForBarChartPopin: false,
    productReferencesList: defaultProductList,
    productionShortfallUnit: "",
    waterInjectShortfallUnit: "",
    flaringUnit: "",
    isReportingWeeklyReportExpanded: false,
    dateRangeForWeeklyReport: "",
  },
  mutations: {
    setUserPicture(state, picture) {
      state.userPicture = picture;
    },
    setAuthInfoMSGraph(state, authInfo) {
      state.authInfoMSGraph = authInfo;
    },
    setAuthInfoAPI(state, authInfo) {
      state.authInfoAPI = authInfo;
    },
    setEquipmentList(state, equipmentList: Equipment[]) {
      state.equipmentList = equipmentList;
      state.equipmentListFiltered = equipmentList;
    },
    setEquipmentListForWeeklyReport(state, equipmentListFiltered: Equipment[]) {
      state.equipmentListForWeeklyReport = equipmentListFiltered;
    },
    setEquipmentParam(state, equipmentParam: EquipmentParamDetails) {
      state.equipmentParam = equipmentParam;
    },
    setEquipmentDetails(state, equipmentDetails: Equipment) {
      state.equipmentDetails = equipmentDetails;
    },
    setEquipmentCommentsForBarChart(
      state,
      equipmentCommentsForBarChart: EquipmentComment[]
    ) {
      state.equipmentCommentForBarChart = equipmentCommentsForBarChart;
    },
    setCurrentSelectedEquipment(state, equipment: Equipment) {
      state.currentSelectedEquipment = equipment;
    },
    setEquipmentDetailsStatus(state, status: EquipmentStatus) {
      state.equipmentDetails.lastEquipmentStatus = status;
    },
    setNewStatus(state, status: EquipmentStatus) {
      state.equipmentDetails.equipmentStatuses.push(status);
    },
    setModifiedStatus(state, status: EquipmentStatus) {
      _.map(
        state.equipmentDetails.equipmentStatuses,
        (equipmentStatusElement: EquipmentStatus) => {
          if (equipmentStatusElement.id === status.id) {
            equipmentStatusElement.date = status.date;
            equipmentStatusElement.event = status.event;
            equipmentStatusElement.status = status.status;
            equipmentStatusElement.equipmentMajorFailure =
              status.equipmentMajorFailure;
            equipmentStatusElement.gasOrOilLeak = status.gasOrOilLeak;
            equipmentStatusElement.fireConfirmed = status.fireConfirmed;
            equipmentStatusElement.equipmentDetailsId =
              status.equipmentDetailsId;
            equipmentStatusElement.tradeTypeId = status.tradeTypeId;
            equipmentStatusElement.productionShortfall =
              status.productionShortfall;
            equipmentStatusElement.waterInjectShortfall =
              status.waterInjectShortfall;
            equipmentStatusElement.flaring = status.flaring;
            equipmentStatusElement.comment = status.comment;
            equipmentStatusElement.serialNumber = status.serialNumber;
            equipmentStatusElement.installationDate = moment(
              status.date
            ).format("DD-MM-YY");
          }
        }
      );
    },
    setDeletedStatus(state, statusId: number) {
      const foundStatusIndex = _.findIndex(
        state.equipmentDetails.equipmentStatuses,
        (status: EquipmentStatus) => {
          return status.id === statusId;
        }
      );
      if (foundStatusIndex !== -1) {
        state.equipmentDetails.equipmentStatuses.splice(foundStatusIndex, 1);
      }
    },
    setLastEquipmentStatus(state) {
      state.equipmentDetails.lastEquipmentStatus = _.maxBy(
        state.equipmentDetails.equipmentStatuses,
        "date"
      );
    },
    setEquipmentDetailsLinks(state, equipmentLink: EquipmentLink) {
      const index = _.findIndex(
        state.equipmentDetails.equipmentLinks,
        (link: EquipmentLink) => {
          return link.id === equipmentLink.id;
        }
      );
      if (index !== -1) {
        state.equipmentDetails.equipmentLinks.splice(index, 1, equipmentLink);
      } else {
        state.equipmentDetails.equipmentLinks.push(equipmentLink);
      }
    },
    setDeletedEquipmentDetailsLinks(state, equipmentLink: EquipmentLink) {
      const index = _.findIndex(
        state.equipmentDetails.equipmentLinks,
        (link: EquipmentLink) => {
          return link.id === equipmentLink.id;
        }
      );
      if (index !== -1) {
        state.equipmentDetails.equipmentLinks.splice(index, 1);
      }
    },
    setDisplayAddLinkPopin(state, displayAddLinkPopin) {
      state.displayAddLinkPopin = displayAddLinkPopin;
    },
    setDisplayAddOilAnalysisPopin(state, displayAddOilAnalysisPopin: boolean) {
      state.displayAddOilAnalysisPopin = displayAddOilAnalysisPopin;
    },
    setDisplayAddVibrationAnalysisPopin(
      state,
      displayAddVibrationAnalysisPopin: boolean
    ) {
      state.displayAddVibrationAnalysisPopin = displayAddVibrationAnalysisPopin;
    },
    setSasToken(state, token) {
      state.sasToken = token;
    },
    setAddLastStatusDialog(state, displayAddLastStatusDialog) {
      state.displayAddLastStatusDialog = displayAddLastStatusDialog;
    },
    setStatusToUpdate(state, status: EquipmentStatus) {
      state.statusToUpdate = status;
    },
    setDateToUpdate(state, date: string) {
      state.dateToUpdate = date;
    },
    setDisplayStatusTimeline(state, displayStatusTimeline) {
      state.displayStatusTimeline = displayStatusTimeline;
    },
    setEquipmentStatisticsList(state, equipmentStatisticsList) {
      state.equipmentStatisticsList = equipmentStatisticsList;
    },
    setNewCommentInEquipmentList(state, { equipmentId, equipmentComment }) {
      const equipmentList = state.equipmentList;
      const foundIndex = equipmentList.findIndex((equipment: Equipment) => {
        return equipment.id === equipmentId;
      });
      if (foundIndex !== -1) {
        const foundIndexComment = equipmentList[
          foundIndex
        ].weeklyReportComments?.findIndex((comment: EquipmentComment) => {
          return comment.id === equipmentComment.id;
        });
        if (foundIndexComment !== undefined && foundIndexComment !== -1) {
          if (!equipmentComment.addToWeeklyReport) {
            equipmentList[foundIndex].weeklyReportComments.splice(
              foundIndexComment,
              1
            );
          } else {
            equipmentList[foundIndex].weeklyReportComments[foundIndexComment] =
              equipmentComment;
          }
        }
      }
      state.equipmentList = equipmentList;
    },
    setReportingView(state, selectedReportingView) {
      state.reportingView = selectedReportingView;
    },
    setEquipmentMlStatuses(state, equipmentMlStatuses) {
      state.equipmentMlStatuses = equipmentMlStatuses;
    },
    setEquipmentMlStatusInList(state, equipmentMlStatus) {
      _.map(
        state.equipmentMlStatuses,
        (equipmentMlElement: EquipmentMlStatus) => {
          if (equipmentMlElement.id === equipmentMlStatus.id) {
            equipmentMlElement.unread = equipmentMlStatus.unread;
            equipmentMlElement.feedback = equipmentMlStatus.feedback;
          }
        }
      );
    },
    setUnreadAlertsTotal(state, unreadAlertsTotal) {
      state.unreadAlertsTotal = unreadAlertsTotal;
      sessionStorage.setItem(
        "unreadAlertsTotal",
        JSON.stringify(unreadAlertsTotal)
      );
    },
    setDisplayAddCommentPopin(state, displayAddCommentPopin) {
      state.displayAddCommentPopin = displayAddCommentPopin;
    },
    setEquipmentDetailsComments(state, equipmentComment) {
      state.equipmentDetails.equipmentComments.push(equipmentComment);
    },
    setDisplayHistoricalStatusDialog(state, displayHistoricalStatusDialog) {
      state.displayHistoricalStatusDialog = displayHistoricalStatusDialog;
    },
    setSelectedStatusEvent(state, selectedStatusEvent) {
      state.selectedStatusEvent = selectedStatusEvent;
    },
    setSelectedTrade(state, selectedTrade) {
      state.selectedTrade = selectedTrade;
    },
    setSelectedEquipment(state, selectedEquipment) {
      state.selectedEquipment = selectedEquipment;
    },
    setEquipmentDetailsCommentFiles(
      state,
      { equipmentComment, equipmentCommentFile }
    ) {
      _.map(
        state.equipmentDetails.equipmentComments,
        (comment: EquipmentComment) => {
          if (comment.id === equipmentComment.id) {
            comment.equipmentCommentFiles.push(equipmentCommentFile);
          }
        }
      );
    },
    setDisplayEditCommentPopin(state, displayEditCommentPopin) {
      state.displayEditCommentPopin = displayEditCommentPopin;
    },
    setEquipmentCommentDetails(
      state,
      equipmentCommentDetails: EquipmentComment
    ) {
      state.equipmentCommentDetails = equipmentCommentDetails;
    },
    setEquipmentCommentInList(state, equipmentComment) {
      _.map(
        state.equipmentDetails.equipmentComments,
        (comment: EquipmentComment) => {
          if (comment.id === equipmentComment.id) {
            comment.date = equipmentComment.date;
            comment.author = equipmentComment.author;
            comment.comment = equipmentComment.comment;
            comment.woNumber = equipmentComment.woNumber;
            comment.woStatus = equipmentComment.woStatus;
            comment.equipmentCommentFiles =
              equipmentComment.equipmentCommentFiles;
            comment.status = equipmentComment.status;
            comment.analysisType = equipmentComment.analysisType;
            comment.informationType = equipmentComment.informationType;
            comment.qualityKpi = equipmentComment.qualityKpi;
            comment.elecMoistureWater = equipmentComment.elecMoistureWater;
            comment.elecDissolvedGasHydrogen =
              equipmentComment.elecDissolvedGasHydrogen;
            comment.elecDissolvedGasMethane =
              equipmentComment.elecDissolvedGasMethane;
            comment.elecDissolvedGasAcethylene =
              equipmentComment.elecDissolvedGasAcethylene;
            comment.elecDissolvedGasEthylene =
              equipmentComment.elecDissolvedGasEthylene;
            comment.elecDissolvedGasEthane =
              equipmentComment.elecDissolvedGasEthane;
            comment.elecDissolvedGasMonoxide =
              equipmentComment.elecDissolvedGasMonoxide;
            comment.elecDissolvedGasDioxide =
              equipmentComment.elecDissolvedGasDioxide;
            comment.elecDissolvedGasTdcg =
              equipmentComment.elecDissolvedGasTdcg;
            comment.failureType = equipmentComment.failureType;
            comment.mean = equipmentComment.mean;
            comment.standardDeviation = equipmentComment.standardDeviation;
            comment.ratioMeanStandardDeviation =
              equipmentComment.ratioMeanStandardDeviation;
            comment.addToWeeklyReport = equipmentComment.addToWeeklyReport;
            comment.isAlert = equipmentComment.isAlert;
            comment.oilType = equipmentComment.oilType;
            comment.dryGasSealComponent = equipmentComment.dryGasSealComponent;
          }
        }
      );
    },
    setDeletedEquipmentDetailsComments(
      state,
      equipmentComment: EquipmentComment
    ) {
      const index = _.findIndex(
        state.equipmentDetails.equipmentComments,
        (comment: EquipmentComment) => {
          return comment.id === equipmentComment.id;
        }
      );
      if (index !== -1) {
        state.equipmentDetails.equipmentComments.splice(index, 1);
      }
    },
    setFilters(
      state,
      {
        selectedEquipmentFilter,
        criticalCheckboxFilter,
        selectedStatusFilter,
        selectedDateFilter,
        selectedEquipmentAlertsFilter,
        selectedSectorFilter,
        statusCheckboxFilter,
      }
    ) {
      state.equipmentListFiltered = state.equipmentList;
      if (selectedEquipmentFilter?.length > 0) {
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            const equipmentName =
              equipment.equipmentModel.name +
              (equipment.tag ? "-" + equipment.tag : "");
            return selectedEquipmentFilter.includes(equipmentName) > 0;
          }
        );
      }
      if (criticalCheckboxFilter) {
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            return equipment.equipmentModel.category === "Critical";
          }
        );
      }
      if (selectedStatusFilter?.length > 0) {
        const allStatusValue: string[] = [];
        selectedStatusFilter.forEach((statusFilter: any) => {
          allStatusValue.push(statusFilter.value);
        });
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            if (equipment.lastEquipmentStatus?.status) {
              return allStatusValue.includes(
                equipment.lastEquipmentStatus?.status
              );
            } else return false;
          }
        );
      }
      if (selectedDateFilter) {
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            return moment(equipment.lastEquipmentStatus?.date).isSameOrAfter(
              moment(selectedDateFilter)
            );
          }
        );
      }
      if (selectedEquipmentAlertsFilter.length > 0) {
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            if (equipment.commentAlertsTypes) {
              const commentAlertsTypes = Object.keys(
                JSON.parse(equipment.commentAlertsTypes)
              );
              return commentAlertsTypes.some((alert) =>
                selectedEquipmentAlertsFilter.includes(alert)
              );
            } else return false;
          }
        );
      }
      if (selectedSectorFilter?.length > 0) {
        state.equipmentListFiltered = _.filter(
          state.equipmentListFiltered,
          (equipment: Equipment) => {
            if (equipment.sector) {
              return selectedSectorFilter.includes(equipment.sector) > 0;
            } else return false;
          }
        );
      }

      state.selectedEquipmentFilter = selectedEquipmentFilter;
      state.criticalCheckboxFilter = criticalCheckboxFilter;
      state.selectedStatusFilter = selectedStatusFilter;
      state.selectedDateFilter = selectedDateFilter;
      state.selectedEquipmentAlertsFilter = selectedEquipmentAlertsFilter;
      state.selectedSectorFilter = selectedSectorFilter;
      state.statusCheckboxFilter = statusCheckboxFilter;
    },
    setUnreadAlertTotal(state, unread) {
      let total = state.unreadAlertsTotal;
      if (!total) {
        const totalInSessionStorage =
          sessionStorage.getItem("unreadAlertsTotal");
        if (totalInSessionStorage && JSON.parse(totalInSessionStorage) > 0)
          total = JSON.parse(totalInSessionStorage);
      }
      const unreadTotal = unread ? total + 1 : total - 1;
      state.unreadAlertsTotal = unreadTotal;
      sessionStorage.setItem("unreadAlertsTotal", JSON.stringify(unreadTotal));
    },
    setEquipmentListFiltered(state, equipmentListFiltered) {
      state.equipmentListFiltered = equipmentListFiltered;
    },
    setSelectedCountryBloc(state, selectedCountryBloc: string) {
      state.selectedCountryBloc = selectedCountryBloc;
      sessionStorage.setItem("selectedCountryBloc", selectedCountryBloc);
      if (
        state.selectedCountryBloc === "Angola-B17" ||
        state.selectedCountryBloc === "Angola-B32"
      ) {
        state.selectedCountryBlocInShort = "Angola";
        state.flaringUnit = "MSCM";
        state.waterInjectShortfallUnit = "bbl";
        state.productionShortfallUnit = "bbl";
      } else if (
        state.selectedCountryBloc === "Denmark-DUC" ||
        state.selectedCountryBloc === "Denmark-NS"
      ) {
        state.selectedCountryBlocInShort = "Denmark";
        state.flaringUnit = "kboe";
        state.waterInjectShortfallUnit = "kbbl";
        state.productionShortfallUnit = "kboe";
      } else if (state.selectedCountryBloc === "Nigeria-DW") {
        state.selectedCountryBlocInShort = "Nigeria";
        state.flaringUnit = "bbl";
        state.waterInjectShortfallUnit = "bbl";
        state.productionShortfallUnit = "bbl";
      } else if (state.selectedCountryBloc === "Congo") {
        state.selectedCountryBlocInShort = "Congo";
        state.flaringUnit = "bbl";
        state.waterInjectShortfallUnit = "bbl";
        state.productionShortfallUnit = "bbl";
      } else if (state.selectedCountryBloc === "UK") {
        state.selectedCountryBlocInShort = "UK";
        state.flaringUnit = "bbl";
        state.waterInjectShortfallUnit = "bbl";
        state.productionShortfallUnit = "bbl";
      } else {
        state.selectedCountryBlocInShort = state.selectedCountryBloc;
        state.flaringUnit = "bbl";
        state.waterInjectShortfallUnit = "bbl";
        state.productionShortfallUnit = "bbl";
      }
    },
    setSelectedSite(state, selectedSite: string) {
      state.selectedSite = selectedSite;
      sessionStorage.setItem("selectedSite", selectedSite);
    },
    setAssignedCountriesAndSites(state, assignedCountriesAndSites: []) {
      state.assignedCountriesAndSites = assignedCountriesAndSites.sort(
        (obj1: AssignedCountryAndSite, obj2: AssignedCountryAndSite) =>
          obj1.countryAndBloc < obj2.countryAndBloc ? -1 : 1
      );
      sessionStorage.setItem(
        "assignedCountriesAndSites",
        JSON.stringify(assignedCountriesAndSites)
      );
    },
    setIsCommentPopinReadOnly(state, isCommentPopinReadOnly) {
      state.isCommentPopinReadOnly = isCommentPopinReadOnly;
    },
    setIsEditModeHistoricalStatusDialog(
      state,
      isEditModeHistoricalStatusDialog
    ) {
      state.isEditModeHistoricalStatusDialog = isEditModeHistoricalStatusDialog;
    },
    setQuickDashboardEquipments(state, quickDashboardEquipments) {
      state.quickDashboardEquipments = quickDashboardEquipments;
    },
    setUserRole(state, userRole) {
      state.userRole = userRole;
      sessionStorage.setItem("userRole", userRole);
    },
    setDisplayRealAlertPopin(state, displayRealAlertPopin) {
      state.displayRealAlertPopin = displayRealAlertPopin;
    },
    setSelectedEquipmentMlStatus(state, equipmentMlStatus) {
      state.selectedEquipmentMlStatus = equipmentMlStatus;
    },
    setExpandedItems(state, expandedItems) {
      state.expandedItems = expandedItems;
    },
    setAreAllExpanded(state, areAllExpanded) {
      state.areAllExpanded = areAllExpanded;
    },
    setDisplayHttpErrorDialog(state, displayHttpErrorDialog) {
      state.displayHttpErrorDialog = displayHttpErrorDialog;
    },
    setHttpErrorMessage(state, httpErrorMessage) {
      state.httpErrorMessage = httpErrorMessage;
    },
    setSparePartsInEquipmentDetails(state, spareParts) {
      state.equipmentDetails.spareParts = spareParts;
    },
    setDeleteSparePartsInEquipmentDetails(state, materialNumber) {
      if (state.equipmentDetails.spareParts) {
        const indexesToBeRemoved: number[] = [];
        state.equipmentDetails.spareParts.forEach(
          (sparePart: SparePart, index: number) => {
            if (sparePart.materialNumber === materialNumber) {
              indexesToBeRemoved.push(index);
            }
          }
        );
        if (indexesToBeRemoved.length > 0) {
          for (let i = indexesToBeRemoved.length - 1; i >= 0; i--) {
            state.equipmentDetails.spareParts.splice(indexesToBeRemoved[i], 1);
          }
        }
      }
    },
    setAddSparePartsInEquipmentDetails(state, newSpareParts) {
      if (!state.equipmentDetails.spareParts)
        state.equipmentDetails.spareParts = [];
      newSpareParts.forEach((sparePart: SparePart) => {
        state.equipmentDetails.spareParts.push(sparePart);
      });
    },
    setDisplayAddSparePartDialog(state, displayAddSparePartDialog) {
      state.displayAddSparePartDialog = displayAddSparePartDialog;
    },
    setAutomaticResponseDetails(state, AutomaticResponse) {
      state.automaticStatusData = AutomaticResponse;
    },
    setSelectedStatus(state, selectedStatusResponse) {
      state.selectedStatusData = selectedStatusResponse;
    },
    setDisplayNormalBehaviorPopin(state, displayNormalBehaviorPopin) {
      state.displayNormalBehaviorPopin = displayNormalBehaviorPopin;
    },
    setProductReferencesList(state, productReferencesList: ProductReference[]) {
      state.productReferencesList = productReferencesList;
    },
    setDisplayAddOrUpdateProductReferencePopin(
      state,
      displayAddOrUpdateProductReferencePopin
    ) {
      state.displayAddOrUpdateProductReferencePopin =
        displayAddOrUpdateProductReferencePopin;
    },
    setDisplayEquipmentCommentForBarChartPopin(
      state,
      displayEquipmentCommentForBarChartPopin
    ) {
      state.displayEquipmentCommentForBarChartPopin =
        displayEquipmentCommentForBarChartPopin;
    },
    setProductReferences(state, productReference: ProductReference) {
      const index = _.findIndex(
        state.productReferencesList,
        (reference: ProductReference) => {
          return reference.id === productReference.id;
        }
      );
      if (index !== -1) {
        state.productReferencesList.splice(index, 1, productReference);
      } else {
        state.productReferencesList.push(productReference);
      }
    },
    setDeletedProductReferences(state, productReference: ProductReference) {
      const index = _.findIndex(
        state.productReferencesList,
        (reference: ProductReference) => {
          return reference.id === productReference.id;
        }
      );
      if (index !== -1) {
        state.productReferencesList.splice(index, 1);
      }
    },
    setIsWeeklyReportExpanded(state, isExpanded: boolean) {
      state.isReportingWeeklyReportExpanded = isExpanded;
    },
    setDateRangeForWeeklyReport(state, value: string) {
      state.dateRangeForWeeklyReport = value;
    },
  },
  actions: {
    async equipmentSelectorMounted({ commit }) {
      const response = await EquipmentApi.getEquipmentList(
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );

      if (response.equipments && isSuccess(response.httpStatus)) {
        commit("setEquipmentList", response.equipments);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Equipments : " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async equipmentListLoaded({ commit }) {
      const response = await EquipmentApi.getEquipmentList(
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipments && isSuccess(response.httpStatus)) {
        const numberOfUnreadAlerts = _.sumBy(
          response.equipments,
          "unreadAlerts"
        );
        commit("setEquipmentList", response.equipments);
        commit("setFilters", {
          selectedEquipmentFilter: this.state.selectedEquipmentFilter,
          criticalCheckboxFilter: this.state.criticalCheckboxFilter,
          selectedStatusFilter: this.state.selectedStatusFilter,
          selectedDateFilter: this.state.selectedDateFilter,
          selectedEquipmentAlertsFilter:
            this.state.selectedEquipmentAlertsFilter,
          selectedSectorFilter: this.state.selectedSectorFilter,
          statusCheckboxFilter: this.state.statusCheckboxFilter,
        });
        commit("setUnreadAlertsTotal", numberOfUnreadAlerts);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Equipments : " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async reportsUpdated({ commit }, { fromDate, toDate }) {
      const response = await EquipmentApi.getEquipmentListForStats(
        fromDate,
        toDate,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipments && isSuccess(response.httpStatus)) {
        commit("setEquipmentListForWeeklyReport", response.equipments);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get equipments for stats : " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async equipmentDetailsPageLoaded(
      { commit },
      { equipmentModelName, equipmentTag }
    ) {
      const getEquipmentDetailsResult =
        await EquipmentApi.getEquipmentBasicInfos(
          equipmentModelName,
          equipmentTag,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
      if (getEquipmentDetailsResult.equipment) {
        const equipmentDetails = getEquipmentDetailsResult.equipment;
        const getEquipmentStatusesResults =
          await EquipmentApi.getEquipmentStatuses(
            equipmentDetails.id,
            this.state.selectedCountryBloc,
            this.state.selectedSite
          );
        const getEquipmentStatusesParamResults =
          await EquipmentApi.getEquipmentStatusesParameters(
            equipmentDetails.id,
            this.state.selectedCountryBloc,
            this.state.selectedSite
          );
        if (
          getEquipmentStatusesParamResults &&
          isSuccess(getEquipmentStatusesParamResults.httpStatus)
        ) {
          commit(
            "setEquipmentParam",
            getEquipmentStatusesParamResults.equipmentStatusesParam
          );
        } else {
          commit(
            "setHttpErrorMessage",
            "Get equipments param : " +
              getMessageByStatusCode(
                getEquipmentStatusesParamResults.httpStatus
              )
          );
          commit("setDisplayHttpErrorDialog", true);
        }
        const getEquipmentCommentsResult =
          await EquipmentApi.getEquipmentComments(
            equipmentDetails.id,
            this.state.selectedCountryBloc,
            this.state.selectedSite
          );
        const getEquipmentLinksResult = await EquipmentApi.getEquipmentLinks(
          equipmentDetails.id,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
        if (getEquipmentStatusesResults.equipmentStatuses) {
          equipmentDetails.equipmentStatuses =
            getEquipmentStatusesResults.equipmentStatuses;
          equipmentDetails.lastEquipmentStatus = _.maxBy(
            getEquipmentStatusesResults.equipmentStatuses,
            (equipmentStatus) => equipmentStatus.date
          );
        } else {
          commit(
            "setHttpErrorMessage",
            "Get equipment statuses: " +
              getMessageByStatusCode(getEquipmentStatusesResults.httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        }

        if (getEquipmentCommentsResult.equipmentComments) {
          equipmentDetails.equipmentComments =
            getEquipmentCommentsResult.equipmentComments;
        } else {
          commit(
            "setHttpErrorMessage",
            "Get equipment comments: " +
              getMessageByStatusCode(getEquipmentStatusesResults.httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        }

        if (getEquipmentLinksResult.equipmentLinks) {
          equipmentDetails.equipmentLinks =
            getEquipmentLinksResult.equipmentLinks;
        } else {
          commit(
            "setHttpErrorMessage",
            "Get equipment links: " +
              getMessageByStatusCode(getEquipmentLinksResult.httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        }

        commit("setEquipmentDetails", equipmentDetails);
        commit("setCurrentSelectedEquipment", equipmentDetails);
      } else {
        if (!isSuccess(getEquipmentDetailsResult.status)) {
          commit(
            "setHttpErrorMessage",
            "Equipment: " +
              getMessageByStatusCode(getEquipmentDetailsResult.status)
          );
          commit("setDisplayHttpErrorDialog", true);
        } else {
          commit("setEquipmentDetails", null);
          commit("setCurrentSelectedEquipment", null);
        }
      }
    },
    async equipmentCommentsForBarChart({ commit }, equipmentId) {
      const getEquipmentCommentsResult =
        await EquipmentApi.getEquipmentComments(
          equipmentId,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
      commit(
        "setEquipmentCommentsForBarChart",
        getEquipmentCommentsResult.equipmentComments
      );
    },

    async updateEquipmentDetails({ commit }, equipment) {
      commit("setEquipmentDetails", equipment);
    },
    async updateCurrentSelectedEquipment({ commit }, equipment) {
      commit("setCurrentSelectedEquipment", equipment);
    },
    async equipmentDetailsReset({ commit }) {
      commit("setEquipmentDetails", null);
      commit("setCurrentSelectedEquipment", null);
    },
    async currentSelectedEquipmentReset({ commit }) {
      commit("setCurrentSelectedEquipment", null);
    },
    async addEquipmentStatus(
      { commit },
      {
        status,
        equipmentId,
        statusDate,
        event,
        commentId,
        isLastStatus,
        equipmentMajorFailure,
        gasOrOilLeak,
        fireConfirmed,
        equipmentDetailsId,
        tradeTypeId,
        productionShortfall,
        waterInjectShortfall,
        flaring,
        equipmentComment,
        serialNumber,
      }
    ): Promise<boolean> {
      const timezone = moment.tz.guess();
      const dateTz = moment.tz(statusDate, timezone).format();
      const equipmentStatus = await EquipmentApi.addEquipmentStatus(
        status,
        equipmentId,
        moment(dateTz),
        event,
        equipmentMajorFailure,
        gasOrOilLeak,
        fireConfirmed,
        equipmentDetailsId,
        tradeTypeId,
        productionShortfall,
        waterInjectShortfall,
        flaring,
        commentId,
        serialNumber,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (
        equipmentStatus.equipmentStatus &&
        isSuccess(equipmentStatus.httpStatus)
      ) {
        const status = {
          id: equipmentStatus.equipmentStatus.id,
          date: equipmentStatus.equipmentStatus.date,
          status: equipmentStatus.equipmentStatus.status,
          event: equipmentStatus.equipmentStatus.event,
          equipmentMajorFailure:
            equipmentStatus.equipmentStatus.equipmentMajorFailure,
          gasOrOilLeak: equipmentStatus.equipmentStatus.gasOrOilLeak,
          fireConfirmed: equipmentStatus.equipmentStatus.fireConfirmed,
          equipmentDetailsId:
            equipmentStatus.equipmentStatus.equipmentDetailsId,
          tradeTypeId: equipmentStatus.equipmentStatus.tradeTypeId,
          productionShortfall:
            equipmentStatus.equipmentStatus.productionShortfall,
          waterInjectShortfall:
            equipmentStatus.equipmentStatus.waterInjectShortfall,
          flaring: equipmentStatus.equipmentStatus.flaring,
          commentId: equipmentStatus.equipmentStatus.commentId,
          serialNumber: equipmentStatus.equipmentStatus.serialNumber,
          installationDate: equipmentStatus.equipmentStatus.date,
        };
        if (equipmentComment) {
          equipmentComment.status = status;
        }
        equipmentStatus.equipmentStatus.comment = equipmentComment;
        equipmentStatus.equipmentStatus.installationDate =
          moment(dateTz).format("DD-MM-YY");
        if (isLastStatus) {
          commit("setEquipmentDetailsStatus", equipmentStatus.equipmentStatus);
        }
        commit("setNewStatus", equipmentStatus.equipmentStatus);
        commit("setEquipmentCommentDetails", equipmentComment);
      } else {
        commit(
          "setHttpErrorMessage",
          "Add equipment status: " +
            getMessageByStatusCode(equipmentStatus.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isSuccess(equipmentStatus.httpStatus);
    },
    async modifyEquipmentStatus(
      { commit },
      {
        statusId,
        status,
        statusDate,
        event,
        commentId,
        equipmentMajorFailure,
        gasOrOilLeak,
        fireConfirmed,
        equipmentDetailsId,
        tradeTypeId,
        productionShortfall,
        waterInjectShortfall,
        flaring,
        equipmentComment,
        serialNumber,
        automaticStatus,
      }
    ): Promise<boolean> {
      const timezone = moment.tz.guess();
      const dateTz = moment.tz(statusDate, timezone).format();
      const response = await EquipmentApi.updateEquipmentStatus(
        statusId,
        status,
        moment(dateTz),
        event,
        equipmentMajorFailure,
        gasOrOilLeak,
        fireConfirmed,
        equipmentDetailsId,
        tradeTypeId,
        productionShortfall,
        waterInjectShortfall,
        flaring,
        commentId,
        serialNumber,
        this.state.selectedCountryBloc,
        this.state.selectedSite,
        automaticStatus
      );
      if (response.updatedEquipmentStatus && isSuccess(response.httpStatus)) {
        const status = {
          id: response.updatedEquipmentStatus.id,
          date: response.updatedEquipmentStatus.date,
          status: response.updatedEquipmentStatus.status,
          event: response.updatedEquipmentStatus.event,
          equipmentMajorFailure:
            response.updatedEquipmentStatus.equipmentMajorFailure,
          gasOrOilLeak: response.updatedEquipmentStatus.gasOrOilLeak,
          fireConfirmed: response.updatedEquipmentStatus.fireConfirmed,
          equipmentDetailsId:
            response.updatedEquipmentStatus.equipmentDetailsId,
          tradeTypeId: response.updatedEquipmentStatus.tradeTypeId,
          productionShortfall:
            response.updatedEquipmentStatus.productionShortfall,
          waterInjectShortfall:
            response.updatedEquipmentStatus.waterInjectShortfall,
          flaring: response.updatedEquipmentStatus.flaring,
          commentId: response.updatedEquipmentStatus.commentId,
          serialNumber: response.updatedEquipmentStatus.serialNumber,
        };
        if (equipmentComment) {
          equipmentComment.status = status;
          equipmentComment.id = response.updatedEquipmentStatus.commentId;
          response.updatedEquipmentStatus.comment = equipmentComment;
          commit("setEquipmentCommentInList", equipmentComment);
          commit("setEquipmentCommentDetails", equipmentComment);
        }
        commit("setModifiedStatus", response.updatedEquipmentStatus);
        commit("setLastEquipmentStatus");
      } else {
        commit(
          "setHttpErrorMessage",
          "Update equipment status: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isSuccess(response.httpStatus);
    },
    async deleteEquipmentStatus({ commit }, statusId): Promise<boolean> {
      const httpStatus = await EquipmentApi.deleteEquipmentStatus(
        statusId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      const isDeleted = isSuccess(httpStatus);
      if (isDeleted) {
        commit("setDeletedStatus", statusId);
        commit("setLastEquipmentStatus");
      } else {
        commit(
          "setHttpErrorMessage",
          "Delete equipment status: " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isDeleted;
    },
    setAccessTokenMSGraph({ commit }, authInfo) {
      commit("setAuthInfoMSGraph", authInfo);
    },
    setAccessTokenAPI({ commit }, authInfo) {
      commit("setAuthInfoAPI", authInfo);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${authInfo.accessToken}`,
      };
    },
    async addEquipmentLink(
      { commit },
      { equipmentLink, equipmentId }
    ): Promise<EquipmentLink | undefined> {
      const response = await EquipmentApi.addEquipmentLink(
        equipmentLink,
        equipmentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentLink && isSuccess(response.httpStatus)) {
        commit("setEquipmentDetailsLinks", response.equipmentLink);
      } else {
        commit(
          "setHttpErrorMessage",
          "Add equipment link: " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.equipmentLink;
    },
    async updateEquipmentLink(
      { commit },
      equipmentLink
    ): Promise<EquipmentLink | undefined> {
      const response = await EquipmentApi.updateEquipmentLink(
        equipmentLink,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (isSuccess(response.httpStatus) && response.equipmentLink) {
        commit("setEquipmentDetailsLinks", response.equipmentLink);
      } else {
        commit(
          "setHttpErrorMessage",
          "Update equipment link: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.equipmentLink;
    },
    async deleteEquipmentLink({ commit }, equipmentLink): Promise<void> {
      const httpStatus = await EquipmentApi.deleteEquipmentLink(
        equipmentLink,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (isSuccess(httpStatus)) {
        commit("setDeletedEquipmentDetailsLinks", equipmentLink);
      } else {
        commit(
          "setHttpErrorMessage",
          "Delete equipment link: " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },

    async addProductReference(
      { commit },
      productReference
    ): Promise<ProductReference | undefined> {
      const response = await ProductApi.addProductReference(productReference);
      if (response.productReferences && isSuccess(response.httpStatus)) {
        commit("setProductReferences", response.productReferences);
      } else {
        commit(
          "setHttpErrorMessage",
          "Add product reference: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.productReferences;
    },

    async updateProductReference(
      { commit },
      productReference
    ): Promise<ProductReference | undefined> {
      const response = await ProductApi.updateProductReference(
        productReference
      );
      if (response.productReferences && isSuccess(response.httpStatus)) {
        commit("setProductReferences", response.productReferences);
      } else {
        commit(
          "setHttpErrorMessage",
          "Update product reference: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.productReferences;
    },

    async deleteProductReference({ commit }, productReferences): Promise<void> {
      const httpStatus = await ProductApi.deleteProductReference(
        productReferences
      );
      if (isSuccess(httpStatus)) {
        commit("setDeletedProductReferences", productReferences);
      } else {
        commit(
          "setHttpErrorMessage",
          "Delete product reference: " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async loadUserPicture({ commit }) {
      const picture = await MsGraphApi.getUserPicture(
        this.state.authInfoMSGraph
      );
      const url = URL.createObjectURL(picture);
      commit("setUserPicture", url);
    },
    displayAddLinkPopin({ commit }, displayAddLinkPopin) {
      commit("setDisplayAddLinkPopin", displayAddLinkPopin);
    },
    displayAddVibrationAnalysisPopin(
      { commit },
      displayAddVibrationAnalysisPopin
    ) {
      commit(
        "setDisplayAddVibrationAnalysisPopin",
        displayAddVibrationAnalysisPopin
      );
    },
    displayAddOilAnalysisPopin(
      { commit },
      displayAddOilAnalysisPopin: boolean
    ) {
      commit("setDisplayAddOilAnalysisPopin", displayAddOilAnalysisPopin);
    },
    async claimSasToken({ commit }): Promise<void> {
      const response = await EquipmentApi.getSasToken();
      if (response.accountSasQueryParams && isSuccess(response.httpStatus)) {
        commit("setSasToken", response.accountSasQueryParams);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Sas Token: " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    displayStatusTimeline({ commit }, displayStatusTimeline) {
      commit("setDisplayStatusTimeline", displayStatusTimeline);
    },
    displayAddLastStatusDialog({ commit }, displayAddLastStatusDialog) {
      commit("setAddLastStatusDialog", displayAddLastStatusDialog);
    },
    updateSelectedDate({ commit }, dateToBeUpdated) {
      commit("setDateToUpdate", dateToBeUpdated);
    },
    updateSelectedStatus({ commit }, statusToBeUpdated) {
      commit("setStatusToUpdate", statusToBeUpdated);
    },
    updateReportingView({ commit }, reportingViewToBeUpdated) {
      commit("setReportingView", reportingViewToBeUpdated);
    },
    async reportPageLoaded({ commit }) {
      const response = await StatisticsApi.getEquipmentList(
        this.state.selectedSite,
        this.state.selectedCountryBloc
      );
      if (response.equipmentStatistics && isSuccess(response.httpStatus)) {
        commit("setEquipmentStatisticsList", response.equipmentStatistics);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Statistics :" + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async reportPageLoadedKPI({ commit }, { fromDate, toDate }) {
      const response = await StatisticsApi.getEquipmentListKpi(
        new Date(fromDate),
        new Date(toDate),
        this.state.selectedSite,
        this.state.selectedCountryBloc
      );
      if (response.equipmentStatistics && isSuccess(response.httpStatus)) {
        commit("setEquipmentStatisticsList", response.equipmentStatistics);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Statistics: " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    reportReset({ commit }) {
      commit("setEquipmentStatisticsList", null);
      commit("setReportingView", null);
    },
    updateCommentsInReportingList(
      { commit },
      { equipmentId, equipmentComment }
    ) {
      commit("setNewCommentInEquipmentList", equipmentId, equipmentComment);
    },
    async equipmentAlertingPageLoaded({ commit }) {
      const response = await EquipmentApi.getAllEquipmentMlStatus(
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentMlStatuses && isSuccess(response.httpStatus)) {
        const equipmentMLStatusesExtended = _.map(
          response.equipmentMlStatuses,
          (equipmentMlStatus: EquipmentMlStatus) => {
            return {
              ...equipmentMlStatus,
              equipmentName:
                equipmentMlStatus.equipment?.equipmentModel?.name +
                " " +
                (equipmentMlStatus.equipment?.tag ?? ""),
            };
          }
        );
        commit("setEquipmentMlStatuses", equipmentMLStatusesExtended);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get all equipment ml statuses: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async updateEquipmentMlStatus(
      { commit },
      {
        equipmentId,
        equipmentMlStatusId,
        unread,
        feedback,
        startDate,
        endDate,
        realAlertValues,
      }
    ): Promise<boolean> {
      const response = await EquipmentApi.updateEquipmentMlStatus(
        equipmentId,
        equipmentMlStatusId,
        unread,
        feedback,
        startDate,
        endDate,
        realAlertValues,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentMlStatus && isSuccess(response.httpStatus)) {
        commit("setEquipmentMlStatusInList", response.equipmentMlStatus);
        commit("setUnreadAlertTotal", unread);
      } else {
        commit(
          "setHttpErrorMessage",
          "Update equipment ml status: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isSuccess(response.httpStatus);
    },
    displayAddCommentPopin({ commit }, displayAddCommentPopin) {
      commit("setDisplayAddCommentPopin", displayAddCommentPopin);
    },
    async addEquipmentComment(
      { commit },
      { equipmentComment, equipmentId }
    ): Promise<EquipmentComment | undefined> {
      const response = await EquipmentApi.addEquipmentComment(
        equipmentComment,
        equipmentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentComment && isSuccess(response.httpStatus)) {
        commit("setEquipmentDetailsComments", response.equipmentComment);
      } else {
        commit(
          "setHttpErrorMessage",
          "Add equipment comment: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.equipmentComment;
    },
    displayHistoricalStatusDialog(
      { commit },
      { displayHistoricalStatusDialog, isEditMode }
    ) {
      commit("setDisplayHistoricalStatusDialog", displayHistoricalStatusDialog);
      commit("setIsEditModeHistoricalStatusDialog", isEditMode);
    },
    updateSelectedStatusEvent({ commit }, selectedStatusEvent) {
      commit("setSelectedStatusEvent", selectedStatusEvent);
    },
    updateSelectedTrade({ commit }, selectedTrade) {
      commit("setSelectedTrade", selectedTrade);
    },
    updateSelectedEquipment({ commit }, selectedEquipment) {
      commit("setSelectedEquipment", selectedEquipment);
    },
    async addEquipmentCommentFile(
      { commit },
      { equipmentCommentFile, equipmentComment, equipmentId }
    ): Promise<EquipmentCommentFile | undefined> {
      const response = await EquipmentApi.addEquipmentCommentFile(
        equipmentCommentFile,
        equipmentComment,
        equipmentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentCommentFile && isSuccess(response.httpStatus)) {
        equipmentCommentFile.id = response.equipmentCommentFile.id;
        commit("setEquipmentDetailsCommentFiles", {
          equipmentComment,
          equipmentCommentFile,
        });
      } else {
        commit(
          "setHttpErrorMessage",
          "Add equipment comment file: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.equipmentCommentFile;
    },
    displayEditCommentPopin(
      { commit },
      { displayEditCommentPopin, isEditPopinReadOnly }
    ) {
      commit("setIsCommentPopinReadOnly", isEditPopinReadOnly);
      commit("setDisplayEditCommentPopin", displayEditCommentPopin);
    },
    async equipmentCommentDetailsLoaded(
      { commit },
      { equipmentId, equipmentCommentId }
    ) {
      const response = await EquipmentApi.getEquipmentCommentDetails(
        equipmentId,
        equipmentCommentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentComment && isSuccess(response.httpStatus)) {
        commit("setEquipmentCommentDetails", response.equipmentComment);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get equipment comment details: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async updateEquipmentCommentDetailsState({ commit }, equipmentComment) {
      commit("setEquipmentCommentDetails", equipmentComment);
    },
    async updateEquipmentComment(
      { commit },
      { equipmentId, equipmentCommentId, equipmentComment }
    ): Promise<EquipmentComment | undefined> {
      const response = await EquipmentApi.updateEquipmentComment(
        equipmentId,
        equipmentCommentId,
        equipmentComment,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.equipmentComment && isSuccess(response.httpStatus)) {
        commit("setEquipmentCommentInList", response.equipmentComment);
      } else {
        commit(
          "setHttpErrorMessage",
          "Update equipment comment: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return response.equipmentComment;
    },
    async deleteEquipmentCommentFile(
      { commit },
      { equipmentCommentFile, equipmentComment, equipmentId }
    ): Promise<boolean> {
      const httpStatus = await EquipmentApi.deleteEquipmentCommentFile(
        equipmentCommentFile,
        equipmentComment,
        equipmentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      const isDeleted = isSuccess(httpStatus);
      if (!isDeleted) {
        commit(
          "setHttpErrorMessage",
          "Delete equipment comment file: " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isDeleted;
    },
    async deleteEquipmentComment(
      { commit },
      { equipmentComment, equipmentId }
    ): Promise<boolean> {
      const httpStatus = await EquipmentApi.deleteEquipmentComment(
        equipmentComment,
        equipmentId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      const isDeleted = isSuccess(httpStatus);
      if (isDeleted) {
        commit("setDeletedEquipmentDetailsComments", equipmentComment);
      } else {
        commit(
          "setHttpErrorMessage",
          "Delete equipment comment: " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isDeleted;
    },
    async updateFilters(
      { commit },
      {
        selectedEquipmentFilter,
        criticalCheckboxFilter,
        selectedStatusFilter,
        selectedDateFilter,
        selectedEquipmentAlertsFilter,
        selectedSectorFilter,
        statusCheckboxFilter,
      }
    ) {
      commit("setFilters", {
        selectedEquipmentFilter,
        criticalCheckboxFilter,
        selectedStatusFilter,
        selectedDateFilter,
        selectedEquipmentAlertsFilter,
        selectedSectorFilter,
        statusCheckboxFilter,
      });
    },
    async resetFilters({ commit }) {
      commit("setFilters", {
        selectedEquipmentFilter: undefined,
        criticalCheckboxFilter: false,
        selectedStatusFilter: [],
        selectedDateFilter: undefined,
        selectedEquipmentAlertsFilter: [],
        selectedSectorFilter: [],
        statusCheckboxFilter: false,
      });
    },
    async updateSelectedCountryBloc({ commit }, selectedCountryBloc) {
      commit("setSelectedCountryBloc", selectedCountryBloc);
    },
    async updateSelectedSiteAndUserRole({ commit }, selectedSite) {
      commit("setSelectedSite", selectedSite);
      const assignedCountries = _.find(
        this.state.assignedCountriesAndSites,
        (assignedCountryAndSites: AssignedCountryAndSite) => {
          return (
            assignedCountryAndSites.countryAndBloc ===
            this.state.selectedCountryBloc
          );
        }
      );
      const siteAndRole = _.find(
        assignedCountries?.sitesAndRoles,
        (siteAndRole: SiteAndRole) => {
          return siteAndRole.site === selectedSite;
        }
      );
      commit("setUserRole", siteAndRole?.role);
    },
    async updateAssignedCountriesAndSitesAndUserRole(
      { commit },
      assignedCountriesAndSites
    ) {
      commit("setAssignedCountriesAndSites", assignedCountriesAndSites);
      const assignedCountries = _.find(
        assignedCountriesAndSites,
        (assignedCountryAndSites: AssignedCountryAndSite) => {
          return (
            assignedCountryAndSites.countryAndBloc ===
            this.state.selectedCountryBloc
          );
        }
      );
      const siteAndRole = _.find(
        assignedCountries?.sitesAndRoles,
        (siteAndRole: SiteAndRole) => {
          return siteAndRole.site === this.state.selectedSite;
        }
      );
      commit("setUserRole", siteAndRole?.role);
    },
    async loadQuickDashboardEquipments({ commit }) {
      const callApiResult = await EquipmentApi.getEquipmentListOffshore(
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (
        callApiResult.offshoreEquipments &&
        isSuccess(callApiResult.httpStatus)
      ) {
        const quickDashboardData: QuickDashboardEquipment[] = [];
        callApiResult?.offshoreEquipments?.forEach(
          (equipmentOffshore: EquipmentOffShore) => {
            const latestComments = {
              oil: null as EquipmentCommentListOffshore | null,
              vibration: null as EquipmentCommentListOffshore | null,
              performance: null as EquipmentCommentListOffshore | null,
            };
            equipmentOffshore.equipmentCommentList?.forEach(
              (comment: EquipmentCommentListOffshore) => {
                if (comment.date) {
                  const commentDate = new Date(comment.date);

                  if (comment.informationType === "oil") {
                    if (
                      !latestComments.oil ||
                      commentDate > new Date(latestComments.oil.date || "")
                    ) {
                      latestComments.oil = comment;
                    }
                  } else if (comment.informationType === "vibration") {
                    if (
                      !latestComments.vibration ||
                      commentDate >
                        new Date(latestComments.vibration.date || "")
                    ) {
                      latestComments.vibration = comment;
                    }
                  } else if (comment.informationType === "performance") {
                    if (
                      !latestComments.performance ||
                      commentDate >
                        new Date(latestComments.performance.date || "")
                    ) {
                      latestComments.performance = comment;
                    }
                  }
                }
              }
            );
            equipmentOffshore.equipmentComments?.forEach(
              (comment: EquipmentCommentOffshore) => {
                const line: QuickDashboardEquipment = {
                  equipmentId: equipmentOffshore.equipmentId,
                  system: equipmentOffshore.system,
                  description: equipmentOffshore.description,
                  equipmentModelName: equipmentOffshore.equipmentModelName,
                  equipmentTag: equipmentOffshore.equipmentTag,
                  lastStatus: equipmentOffshore.lastStatus?.status,
                  criticality: equipmentOffshore.criticality,
                  commentId: comment.id,
                  woNumber: !comment.woNumber
                    ? undefined
                    : comment.woNumber.toString(),
                  woDesignation: comment.woDesignation,
                  condition: mapEquipmentConditionEnumToString(
                    comment.condition
                  ),
                  woStatus: comment.woStatus,
                  comments: comment.comment,
                  commentDate: comment.date,
                  isFreeRow: equipmentOffshore.isFreeRow,
                  id: equipmentOffshore.id,
                  oil: latestComments.oil?.qualityKpi?.toUpperCase() || "Null",
                  vibration:
                    latestComments.vibration?.qualityKpi?.toUpperCase() ||
                    "Null",
                  performance:
                    latestComments.performance?.qualityKpi?.toUpperCase() ||
                    "Null",
                };
                quickDashboardData.push(line);
              }
            );
            if (equipmentOffshore.isFreeRow) {
              const line: QuickDashboardEquipment = {
                equipmentId: undefined,
                system: equipmentOffshore.equipmentCommentFreeRow?.system || "",
                description:
                  equipmentOffshore.equipmentCommentFreeRow?.description || "",
                equipmentModelName: "",
                equipmentTag:
                  equipmentOffshore.equipmentCommentFreeRow?.tag || "",
                lastStatus: undefined,
                criticality:
                  equipmentOffshore.equipmentCommentFreeRow?.criticality || 1,
                commentId: undefined,
                woNumber: !equipmentOffshore.equipmentCommentFreeRow?.woNumber
                  ? undefined
                  : equipmentOffshore.equipmentCommentFreeRow?.woNumber.toString(),
                woDesignation:
                  equipmentOffshore.equipmentCommentFreeRow?.woDesignation,
                condition: mapEquipmentConditionEnumToString(
                  equipmentOffshore.equipmentCommentFreeRow?.condition
                ),
                woStatus: equipmentOffshore.equipmentCommentFreeRow?.woStatus,
                comments: equipmentOffshore.equipmentCommentFreeRow?.comment,
                commentDate: new Date().toString(),
                isFreeRow: equipmentOffshore.isFreeRow,
                id: equipmentOffshore.equipmentCommentFreeRow?.id || "",
                oil: equipmentOffshore?.equipmentCommentFreeRow?.oil || "",
                vibration:
                  equipmentOffshore?.equipmentCommentFreeRow?.vibration || "",
                performance:
                  equipmentOffshore?.equipmentCommentFreeRow?.performance || "",
              };
              quickDashboardData.push(line);
            }
          }
        );
        commit("setQuickDashboardEquipments", quickDashboardData);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get equipments offshore list: " +
            getMessageByStatusCode(callApiResult.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async updateQuickDashboardEquipments(
      { commit },
      { formattedEquipments, quickDashboardEquipments }
    ) {
      const httpStatus = await EquipmentApi.updateEquipmentListOffshore(
        this.state.selectedCountryBloc,
        this.state.selectedSite,
        formattedEquipments
      );
      if (!isSuccess(httpStatus)) {
        commit(
          "setHttpErrorMessage",
          "Update equipment offshore : " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      } else {
        commit("setQuickDashboardEquipments", quickDashboardEquipments);
      }
    },

    async deleteQuickDashboardEquipment(
      { commit },
      { quickDashboardEquipment, quickDashboardEquipmentsList }
    ) {
      let httpStatus = 200;
      if (quickDashboardEquipment.commentId) {
        httpStatus = await EquipmentApi.deleteEquipmentOffshoreComment(
          quickDashboardEquipment.equipmentId,
          quickDashboardEquipment.commentId,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
        if (!isSuccess(httpStatus)) {
          commit(
            "setHttpErrorMessage",
            "Delete equipment offshore : " + getMessageByStatusCode(httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        } else {
          commit("setQuickDashboardEquipments", quickDashboardEquipmentsList);
        }
      }
      if (quickDashboardEquipment.id) {
        httpStatus = await EquipmentApi.deleteEquipmentOffshoreFreeComment(
          quickDashboardEquipment.id,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
        if (!isSuccess(httpStatus)) {
          commit(
            "setHttpErrorMessage",
            "Delete equipment offshore : " + getMessageByStatusCode(httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        } else {
          commit("setQuickDashboardEquipments", quickDashboardEquipmentsList);
        }
      }
    },
    displayRealAlertPopin({ commit }, displayRealAlertPopin) {
      commit("setDisplayRealAlertPopin", displayRealAlertPopin);
    },
    updateSelectedEquipmentMlStatus({ commit }, selectedEquipmentMlStatus) {
      commit("setSelectedEquipmentMlStatus", selectedEquipmentMlStatus);
    },
    updateExpandedItems({ commit }, { expandedItems, areAllExpanded }) {
      commit("setExpandedItems", expandedItems);
      commit("setAreAllExpanded", areAllExpanded);
    },
    async loadSpareParts({ commit }, equipment) {
      const response = await SparePartsApi.getSparePartsForEquipment(
        equipment?.id,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.spareParts && isSuccess(response.httpStatus)) {
        commit("setSparePartsInEquipmentDetails", response.spareParts);
      } else {
        commit(
          "setHttpErrorMessage",
          "Load spare parts : " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async addSparePart(
      { commit },
      { equipmentId, materialNumber }
    ): Promise<SparePart[] | undefined> {
      if (equipmentId !== undefined && materialNumber != undefined) {
        const response = await SparePartsApi.addSparePart(
          equipmentId,
          materialNumber,
          this.state.selectedCountryBloc,
          this.state.selectedSite
        );
        const newSpareParts = response.spareParts;
        if (isSuccess(response.httpStatus) && newSpareParts) {
          if (newSpareParts.length > 0) {
            commit("setAddSparePartsInEquipmentDetails", newSpareParts);
          }
        } else {
          commit(
            "setHttpErrorMessage",
            "Add spare parts : " + getMessageByStatusCode(response.httpStatus)
          );
          commit("setDisplayHttpErrorDialog", true);
        }
        return newSpareParts;
      }
    },
    async deleteSparePart(
      { commit },
      { equipmentId, materialNumber }
    ): Promise<boolean> {
      const httpStatus = await SparePartsApi.deleteSparePart(
        equipmentId,
        materialNumber,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      const isDeleted = isSuccess(httpStatus);
      if (isDeleted) {
        commit("setDeleteSparePartsInEquipmentDetails", materialNumber);
      } else {
        commit(
          "setHttpErrorMessage",
          "Delete spare parts : " + getMessageByStatusCode(httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isDeleted;
    },
    async loadAutomaticStatus({ commit }): Promise<void> {
      const response = await EquipmentApi.getAutomaticStatusUpdate(
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.AutomaticStatus && isSuccess(response.httpStatus)) {
        commit("setAutomaticResponseDetails", response.AutomaticStatus);
      } else {
        commit(
          "setHttpErrorMessage",
          "Load Automatic Response : " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    async confirmSelectedStatus({ commit }, { statusId }): Promise<boolean> {
      const response = await EquipmentApi.confirmEquipmentStatus(
        statusId,
        this.state.selectedCountryBloc,
        this.state.selectedSite
      );
      if (response.selectedEquipmentStatus && isSuccess(response.httpStatus)) {
        commit("setSelectedStatus", response.selectedEquipmentStatus);
      } else {
        commit(
          "setHttpErrorMessage",
          "Confirm Selected Status: " +
            getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
      return isSuccess(response.httpStatus);
    },
    displayAddSparePartDialog({ commit }, displayAddSparePartDialog) {
      commit("setDisplayAddSparePartDialog", displayAddSparePartDialog);
    },
    displayHttpErrorDialog(
      { commit },
      { displayHttpErrorDialog, httpErrorMessage }
    ) {
      commit("setHttpErrorMessage", httpErrorMessage);
      commit("setDisplayHttpErrorDialog", displayHttpErrorDialog);
    },
    displayNormalBehaviorPopin({ commit }, displayNormalBehaviorPopin) {
      commit("setDisplayNormalBehaviorPopin", displayNormalBehaviorPopin);
    },
    async productReferencesLoaded({ commit }) {
      const response = await ProductApi.getProductReferenceList();
      if (response.productReferences && isSuccess(response.httpStatus)) {
        commit("setProductReferencesList", response.productReferences);
      } else {
        commit(
          "setHttpErrorMessage",
          "Get Products : " + getMessageByStatusCode(response.httpStatus)
        );
        commit("setDisplayHttpErrorDialog", true);
      }
    },
    displayAddOrUpdateProductReferencePopin(
      { commit },
      displayAddOrUpdateProductReferencePopin
    ) {
      commit(
        "setDisplayAddOrUpdateProductReferencePopin",
        displayAddOrUpdateProductReferencePopin
      );
    },
    displayEquipmentCommentForBarChartPopin(
      { commit },
      displayEquipmentCommentForBarChartPopin
    ) {
      commit(
        "setDisplayEquipmentCommentForBarChartPopin",
        displayEquipmentCommentForBarChartPopin
      );
    },
    updateIsReportingWeeklyReportExpanded({ commit }, isExpanded) {
      commit("setIsWeeklyReportExpanded", isExpanded);
    },
    updateDateRangeForWeeklyReport({ commit }, value) {
      commit("setDateRangeForWeeklyReport", value);
    },
  },
  modules: {},
  getters: {
    getMsalConfig(state) {
      return state.msalConfig;
    },
    getEquipmentList(state) {
      return state.equipmentList;
    },
    getEquipmentListForWeeklyReport(state) {
      return state.equipmentListForWeeklyReport;
    },
    getEquipmentParam(state) {
      return state.equipmentParam;
    },
    getEquipmentDetails(state) {
      return state.equipmentDetails;
    },
    getautomaticStatusData(state) {
      return state.automaticStatusData;
    },
    getSelectedStatusData(state) {
      return state.selectedStatusData;
    },
    getEquipmentCommentsForBarChart(state) {
      return state.equipmentCommentForBarChart;
    },
    getCurrentSelectedEquipment(state) {
      return state.currentSelectedEquipment;
    },
    getAuthInfoAPI(state) {
      return state.authInfoAPI;
    },
    getAuthInfoMSGraph(state) {
      return state.authInfoMSGraph;
    },
    getUserPicture(state) {
      return state.userPicture;
    },
    getDisplayAddLinkPopin(state) {
      return state.displayAddLinkPopin;
    },
    getDisplayAddOilAnalysisPopin(state) {
      return state.displayAddOilAnalysisPopin;
    },
    getDisplayAddVibrationAnalysisPopin(state) {
      return state.displayAddVibrationAnalysisPopin;
    },
    getSasToken(state) {
      return state.sasToken;
    },
    getAddLastStatusDialog(state) {
      return state.displayAddLastStatusDialog;
    },
    getStatusToUpdate(state) {
      return state.statusToUpdate;
    },
    getDateToUpdate(state) {
      return state.dateToUpdate;
    },
    getDisplayStatusTimeline(state) {
      return state.displayStatusTimeline;
    },
    getEquipmentStatisticsList(state) {
      return state.equipmentStatisticsList;
    },
    getReportingView(state) {
      return state.reportingView;
    },
    getEquipmentMlStatuses(state) {
      return state.equipmentMlStatuses;
    },
    getUnreadAlertsTotal(state) {
      const sessionStorageUnread = sessionStorage.getItem("unreadAlertsTotal");
      const total = state.unreadAlertsTotal
        ? state.unreadAlertsTotal
        : parseInt(sessionStorageUnread ?? "0");
      return total;
    },
    getDisplayAddCommentPopin(state) {
      return state.displayAddCommentPopin;
    },
    getDisplayHistoricalStatusDialog(state) {
      return state.displayHistoricalStatusDialog;
    },
    getSelectedStatusEvent(state) {
      return state.selectedStatusEvent;
    },
    getSelectedTrade(state) {
      return state.selectedTrade;
    },
    getSelectedEquipment(state) {
      return state.selectedEquipment;
    },
    getDisplayEditCommentPopin(state) {
      return state.displayEditCommentPopin;
    },
    getEquipmentCommentDetails(state) {
      return state.equipmentCommentDetails;
    },
    getSelectedStatusFilter(state) {
      return state.selectedStatusFilter;
    },
    getCriticalCheckboxFilter(state) {
      return state.criticalCheckboxFilter;
    },
    getStatusCheckboxFilter(state) {
      return state.statusCheckboxFilter;
    },
    getSelectedDateFilter(state) {
      return state.selectedDateFilter;
    },
    getSelectedSectorFilter(state) {
      return state.selectedSectorFilter;
    },
    getSelectedEquipmentFilter(state) {
      return state.selectedEquipmentFilter;
    },
    getEquipmentListFiltered(state) {
      return state.equipmentListFiltered;
    },
    getSelectedCountryBloc(state) {
      state.selectedCountryBloc = sessionStorage.getItem("selectedCountryBloc");
      return state.selectedCountryBloc;
    },
    getSelectedCountryBlocInShort(state) {
      return state.selectedCountryBlocInShort;
    },
    getSelectedSite(state) {
      state.selectedSite = sessionStorage.getItem("selectedSite");
      return state.selectedSite;
    },
    getAssignedCountriesAndSites(state) {
      state.assignedCountriesAndSites = JSON.parse(
        sessionStorage.getItem("assignedCountriesAndSites") || "{}"
      );
      return state.assignedCountriesAndSites;
    },
    getIsCommentPopinReadOnly(state) {
      return state.isCommentPopinReadOnly;
    },
    getIsEditModeHistoricalStatusDialog(state) {
      return state.isEditModeHistoricalStatusDialog;
    },
    getQuickDashboardEquipments(state) {
      return state.quickDashboardEquipments;
    },
    getUserRole(state) {
      state.userRole = sessionStorage.getItem("userRole");
      return state.userRole;
    },
    getDisplayRealAlertPopin(state) {
      return state.displayRealAlertPopin;
    },
    getSelectedEquipmentMlStatus(state) {
      return state.selectedEquipmentMlStatus;
    },
    getExpandedItems(state) {
      return state.expandedItems;
    },
    getAreAllExpanded(state) {
      return state.areAllExpanded;
    },
    getDisplayAddSparePartDialog(state) {
      return state.displayAddSparePartDialog;
    },
    getHttpErrorMessage(state) {
      return state.httpErrorMessage;
    },
    getDisplayHttpErrorDialog(state) {
      return state.displayHttpErrorDialog;
    },
    getSelectedEquipmentAlertsFilter(state) {
      return state.selectedEquipmentAlertsFilter;
    },
    getDisplayNormalBehaviorPopin(state) {
      return state.displayNormalBehaviorPopin;
    },
    getProductReferences(state) {
      return state.productReferencesList;
    },
    getDisplayAddOrUpdateProductReferencePopin(state) {
      return state.displayAddOrUpdateProductReferencePopin;
    },
    getDisplayEquipmentCommentForBarChartPopin(state) {
      return state.displayEquipmentCommentForBarChartPopin;
    },
    getEquipmentListAvailabilityKPI(state) {
      return state.equipmentList;
    },
    getProductionShortfallUnit(state) {
      return state.productionShortfallUnit;
    },
    getWaterInjectShortfallUnit(state) {
      return state.waterInjectShortfallUnit;
    },
    getFlaringUnit(state) {
      return state.flaringUnit;
    },
    getIsReportingWeeklyReportExpanded(state) {
      return state.isReportingWeeklyReportExpanded;
    },
    getDateRangeForWeeklyReport(state) {
      return state.dateRangeForWeeklyReport;
    },
  },
  plugins: process.env.VUE_APP_VUEX_LOGGER_ENABLED ? [createLogger()] : [],
});
