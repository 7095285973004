
import { Options, Vue } from "vue-class-component";
import type {
  EquipmentAndStatistics,
  EquipmentStatistics,
  SchUnschHours,
} from "../../types/equipment-statistics";
import { mapActions, mapGetters } from "vuex";
import EquipmentSystem from "../equipment/EquipmentSystem.vue";
import EquipmentModel from "../equipment/EquipmentModel.vue";
import EquipmentTag from "../equipment/EquipmentTag.vue";
import { ReportingViewType } from "../../types/reporting-view";
import _ from "lodash";
import fp from "lodash/fp";
import { Equipment } from "../../types/equipment";
import EditEquipmentCommentPopin from "../equipment-details/EditEquipmentCommentPopin.vue";
import { EquipmentComment } from "../../types/equipment-comment";
// import moment from "moment-timezone";
import * as Moment from "moment";
import { UserRole } from "../../types/user-info";
import { MomentInput } from "moment";
import { extendMoment } from "moment-range";
import {
  EquipmentStatus,
  EquipmentStatuses,
} from "../../types/equipment-status";
import { equipmentStatuses } from "@/helpers/equipment-status-helpers";

const moment = extendMoment(Moment);

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
  },
  components: {
    EquipmentSystem,
    EquipmentModel,
    EquipmentTag,
    EditEquipmentCommentPopin,
  },
  methods: {
    ...mapActions([
      "reportPageLoaded",
      "reportReset",
      "equipmentDetailsReset",
      "updateCommentsInReportingList",
      "reportsUpdated",
      "displayHttpErrorDialog",
      "reportPageLoadedKPI",
    ]),
  },
  computed: {
    ...mapGetters([
      "getEquipmentStatisticsList",
      "getReportingView",
      "getEquipmentListForWeeklyReport",
      "getSelectedSite",
      "getEquipmentDetails",
      "EquipmentDetails",
      "getSelectedSite",
      "getUserRole",
      "getSelectedCountryBloc",
      "getIsReportingWeeklyReportExpanded",
    ]),
  },
  data() {
    return {
      expansion_items: {},
      startDate: "",
      endDate: "",
      showPageLoadingInfo: false,
      pageLoadingInfo: "Loading weekly report data...",
    };
  },
  watch: {
    getIsReportingWeeklyReportExpanded(newVal) {
      Object.keys(this.expansion_items).forEach((key) => {
        this.expansion_items[key] = newVal;
      });
    },
    getReportingView: function (val) {
      let initialSDate = moment().startOf("week").format("DD-MM-YYYY");
      let initialEDate = moment().endOf("week").format("DD-MM-YYYY");
      if (
        this.getReportingView === ReportingViewType.BARCHART &&
        this.startDate === initialSDate &&
        this.endDate === initialEDate
      ) {
        this.startDate = "";
        this.endDate = "";
      }
    },
  },
  name: "ReportingCard",
})
export default class ReportingCard extends Vue {
  fromDate = new Date();
  toDate = new Date();
  unschHours = 0;
  schHours = 0;
  ReportingViewType = ReportingViewType;
  getEquipmentStatisticsList!: EquipmentAndStatistics;
  getEquipmentListForWeeklyReport!: Equipment[];
  getReportingView!: string;
  pageLoadingInfo!: string;
  showPageLoadingInfo!: boolean;
  getIsReportingWeeklyReportExpanded!: boolean;
  reportPageLoaded!: () => void;
  reportReset!: () => void;
  expansion_items!: { [key: string]: boolean };
  equipmentDetailsReset!: () => void;
  updateCommentsInReportingList!: ({
    equipmentId,
    equipmentComment,
  }: {
    equipmentId: number;
    equipmentComment: EquipmentComment;
  }) => void;
  reportsUpdated!: ({ fromDate, toDate }: any) => Promise<void>;
  reportPageLoadedKPI!: ({ fromDate, toDate }: any) => Promise<void>;
  getSelectedSite!: string;
  updateEquipmentDetails!: (equipment: Equipment) => Promise<void>;
  getEquipmentDetails!: Equipment;
  equipment!: Equipment;
  manualSortList = [
    "Critical and safety systems",
    "Critical systems",
    "Safety systems",
    "Electric energy",
    "Produced gas processing",
    "Produced liquid processing",
    "Produced water system",
    "Water processing",
    "Subsea processing",
    "Chemical injection",
    "Drain system",
    "Other utilities",
    "Electrical equipment",
    "HVAC",
    "Lifting equipment",
    "Marine",
    "Buoy",
    "Drilling equipment",
    "Dan Bravo",
    "Dagmar",
    "Kraka",
    "Rolf",
    "Roar",
    "Svend",
    "Tyra South East A",
    "Tyra South East B",
    "Valdemar",
  ];
  startDate!: string;
  endDate!: string;
  getUserRole!: UserRole;

  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  getSelectedCountryBloc!: string;

  get equipmentsGroupedBySystemAndModelAndDesc(): EquipmentAndStatistics | any {
    if (this.getReportingView === ReportingViewType.WEEKLY) {
      let eqList = this.getEquipmentListForWeeklyReport;
      let tagIdarr: any[] = [];
      let tempEnquipmentName = "";

      eqList = eqList.filter(
        (eq: Equipment) =>
          eq.hasWeeklyReport || eq.weeklyReportComments?.length > 0
      );
      const equipmentsGroupedBySystem = _.groupBy(
        eqList,
        "equipmentModel.system"
      );
      const equipmentsGroupedBySystemManualOrdered = Object.keys(
        equipmentsGroupedBySystem
      )
        .sort(
          (a, b) =>
            this.manualSortList.indexOf(a) - this.manualSortList.indexOf(b)
        )
        .reduce((obj: any, key) => {
          obj[key] = equipmentsGroupedBySystem[key];
          return obj;
        }, {});

      const equipmentStatistics: EquipmentAndStatistics =
        this.getEquipmentStatisticsList;
      const schUnschHours: SchUnschHours = {};

      Object.keys(equipmentStatistics || {}).forEach((systemKey) => {
        const system = equipmentStatistics[systemKey];
        Object.keys(system).forEach((modelKey) => {
          const model = system[modelKey];
          const allStatuses = Object.keys(model || {}).reduce(
            (acc: EquipmentStatus[], nestedKey) => {
              const nestedModel = model[nestedKey];
              if (nestedModel?.equipments) {
                nestedModel.equipments.forEach((equipment: any) => {
                  const tag = equipment.tag;
                  const sortedStatuses = _.sortBy(
                    equipment.equipmentStatuses,
                    "date"
                  );
                  const filteredStatuses = _.filter(
                    sortedStatuses,
                    (status: EquipmentStatus, index: number) => {
                      const fromDateMoment = moment(this.fromDate);
                      const toDateMoment = moment(this.toDate);
                      return (
                        (moment(status.date).isSameOrAfter(fromDateMoment) &&
                          moment(status.date).isBefore(toDateMoment)) ||
                        (moment(status.date).isSameOrBefore(fromDateMoment) &&
                          moment(sortedStatuses[index + 1]?.date).isAfter(
                            fromDateMoment
                          ))
                      );
                    }
                  );
                  const hoursByStatuses = _.reduce(
                    filteredStatuses,
                    (acc: any, { status, date }, index) => {
                      if (moment(date).isBefore(moment(this.fromDate))) {
                        date = moment(this.fromDate).format();
                      }
                      const nextStatus = filteredStatuses[index + 1];
                      if (!acc[status]) acc[status] = 0;
                      if (nextStatus) {
                        acc[status] +=
                          +moment.range(moment(date), moment(nextStatus.date)) /
                          3600000;
                      } else {
                        acc[status] +=
                          +moment.range(moment(date), moment(this.toDate)) /
                          3600000;
                      }
                      return acc;
                    },
                    {}
                  );
                  let sch = hoursByStatuses[EquipmentStatuses.SCH] ?? 0;
                  let unsch = hoursByStatuses[EquipmentStatuses.UNSCH] ?? 0;

                  if (tagIdarr.length === 0) {
                    const obj = { [equipment?.tag]: { sch, unsch } };
                    tempEnquipmentName = equipment.equipmentModel.name;
                    tagIdarr.push(obj);
                  } else {
                    if (tempEnquipmentName == equipment.equipmentModel.name) {
                      tagIdarr.push({ [equipment?.tag]: { sch, unsch } });
                    } else {
                      tagIdarr = [];
                      tempEnquipmentName = equipment.equipmentModel.name;
                      tagIdarr.push({ [equipment?.tag]: { sch, unsch } });
                    }
                  }
                  schUnschHours[equipment.equipmentModel.name] = tagIdarr;
                });
              }
              return acc;
            },
            []
          );
        });
      });
      let equipmentsGroupedBySystemOrderedTagId: any = {};
      Object.keys(equipmentsGroupedBySystemManualOrdered).forEach((key) => {
        equipmentsGroupedBySystemOrderedTagId[key] = _.sortBy(
          equipmentsGroupedBySystemManualOrdered[key],
          "tagId"
        );
      });
      const equipmentsGroupedBySystemandModelandDesc = _.mapValues(
        equipmentsGroupedBySystemOrderedTagId,
        (groupedItems) => {
          const grouped = fp.groupBy(
            (obj: any) =>
              `${obj.equipmentModel?.name} (${obj.name_description})`,
            groupedItems
          );
          Object.keys(grouped).forEach((key) => {
            grouped[key].forEach((item) => {
              const equipmentName = item.equipmentModel?.name;
              const tag = item.tagId;
              let res: any;
              if (tag == equipmentName + item.tag) {
                Array(schUnschHours).forEach((value) => {
                  Object.keys(value).forEach((key: any) => {
                    if (key === equipmentName) {
                      value[key].forEach((element) => {
                        if (Object.keys(element) == item.tag) {
                          res = element[item.tag];
                        }
                      });
                    }
                  });
                  item.sch = this.formatHours(res?.sch);
                  item.unsch = this.formatHours(res?.unsch);
                });
              }
              // if (equipmentName && schUnschHours[equipmentName] ) {
              //   item.sch = this.formatHours(sch);
              //   item.unsch = this.formatHours(unsch);
              // }
            });
          });
          return grouped;
        }
        // fp.groupBy(function (obj: Equipment) {
        //   return [obj.equipmentModel.name + "(" + obj.name_description + ")"];
        // })
      );
      return equipmentsGroupedBySystemandModelandDesc;
    } else if (this.getReportingView === ReportingViewType.BARCHART) {
      const equipmentsGroupedBySystemManualOrdered: EquipmentAndStatistics =
        Object.keys(this.getEquipmentStatisticsList)
          .sort(
            (a, b) =>
              this.manualSortList.indexOf(a) - this.manualSortList.indexOf(b)
          )
          .reduce((obj: any, key: any) => {
            obj[key] = this.getEquipmentStatisticsList[key];
            return obj;
          }, {});
      return equipmentsGroupedBySystemManualOrdered;
    }
    return {};
  }
  formatHours(value: number): string {
    return `${Math.round(value)}`;
  }

  async mounted(): Promise<void> {
    this.fromDate = new Date(this.getInitialDateRange().min);
    this.toDate = new Date(this.getInitialDateRange().max);
    this.reportPageLoaded();
    if (this.getReportingView === ReportingViewType.WEEKLY) {
      this.showPageLoadingInfo = true;
      this.pageLoadingInfo = "Loading weekly report data...";
      this.$q.loading.show();
      this.startDate = moment().startOf("week").format("DD-MM-YYYY");
      this.endDate = moment().endOf("week").format("DD-MM-YYYY");
      const toDate = moment(this.endDate, "DD MM YYYY hh:mm:ss").toDate();
      const fromDate = moment(this.startDate, "DD MM YYYY hh:mm:ss").toDate();
      //this await function results in slowing down the reporting page.
      this.pageLoadingInfo =
        "Loading comments and 12 months avl/rel data for each equipment in weekly report section...";
      await this.reportsUpdated({
        fromDate,
        toDate,
      });
      this.pageLoadingInfo = "data populated...";
      Object.keys(this.equipmentsGroupedBySystemAndModelAndDesc).forEach(
        (system: string) => {
          this.expansion_items["weekly-report-" + system] =
            this.getIsReportingWeeklyReportExpanded;
        }
      );
      this.showPageLoadingInfo = false;
      this.$q.loading.hide();
    }
  }

  beforeUnmount(): void {
    this.reportReset();
  }
  expand(): void {
    window.dispatchEvent(new Event("resize"));
  }

  onClosedEditEquipmentCommentPopin(): void {
    this.equipmentDetailsReset();
  }

  updatedEquipmentComment(
    equipmentId: number,
    equipmentComment: EquipmentComment
  ): void {
    this.updateCommentsInReportingList({ equipmentId, equipmentComment });
  }
  startDateOptions(date: any): boolean {
    const currentDate = moment(moment()).format("YYYY/MM/DD");
    if (this.endDate != null && this.endDate.toLocaleString() != "") {
      let localEndDate = new Date(this.endDate);
      const minStartDatePossible = moment(
        localEndDate.setMonth(localEndDate.getMonth() - 18)
      ).format("YYYY/MM/DD");
      return (
        date <= currentDate &&
        date >= minStartDatePossible &&
        date <= this.endDate
      );
    } else {
      return date <= currentDate;
    }
  }
  endDateOptions(date: any): boolean {
    let endDate = new Date(this.startDate);
    const currentDate = moment(moment()).format("YYYY/MM/DD");
    const endDates = moment(endDate.setMonth(endDate.getMonth() + 18)).format(
      "YYYY/MM/DD"
    );
    let startDateFormatted = moment(this.startDate).format("YYYY/MM/DD");
    return (
      date >= startDateFormatted && date <= currentDate && date <= endDates
    );
  }
  async apply(): Promise<void> {
    if (this.startDate && this.endDate) {
      this.$q.loading.show();
      const fromDate = this.startDate;
      const toDate = this.endDate;
      await this.reportPageLoadedKPI({
        fromDate,
        toDate,
      });
      this.$q.loading.hide();
    }
  }
  getInitialDateRange(): any {
    const toDate = moment();
    const fromDate = moment().subtract(12, "months");
    return {
      min: fromDate,
      max: toDate,
    };
  }
}
