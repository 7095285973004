
import { Options, Vue } from "vue-class-component";
import { AgGridVue } from "ag-grid-vue3";
import QuickDashboardActions from "../components/quick-dashboard/QuickDashboardActions.vue";
import QuickDashboardConditions from "../components/quick-dashboard/QuickDashboardConditions.vue";
import { ColumnApi, GridApi, RowNode } from "ag-grid-community";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {
  EquipmentCommentOffshore,
  EquipmentOffShore,
  QuickDashboardEquipment,
  EquipmentCommentFreeRow,
} from "../types/quick-dashboard-equipment";
import { isEqual } from "lodash/fp";
import EquipmentSelector from "../components/equipment-details/EquipmentSelector.vue";
import { Equipment } from "../types/equipment";
import { UserRole } from "../types/user-info";
import {
  isUserUnknown,
  isUserGuest,
  isUserOnshore,
} from "../helpers/user-role-helper";
import { mapConditionStringToEnum } from "../helpers/equipment-condition-helper";
import EquipmentApi from "../api/equipment";
import { formatDateWithoutHours } from "../helpers/equipment-status-helpers";
import QuickDashboardLink from "../components/quick-dashboard/QuickDashboardLink.vue";

import {
  isSuccess,
  getMessageByStatusCode,
  treatErrors,
} from "../helpers/http-status-helper";

@Options({
  components: {
    AgGridVue,
    EquipmentSelector,
    QuickDashboardConditions,
    QuickDashboardLink,
  },
  methods: {
    ...mapActions([
      "loadQuickDashboardEquipments",
      "updateQuickDashboardEquipments",
      "currentSelectedEquipmentReset",
      "updateSelectedSiteAndUserRole",
      "displayHttpErrorDialog",
      "updateSelectedCountryBloc",
    ]),
  },
  computed: {
    ...mapGetters([
      "getQuickDashboardEquipments",
      "getCurrentSelectedEquipment",
      "getUserRole",
      "getSelectedSite",
      "getSelectedCountryBloc",
    ]),
  },
  name: "QuickDashboard",
})
export default class QuickDashboard extends Vue {
  isFreeRowBtn!: boolean;
  getUserRole!: UserRole;
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  filter = "";
  getCurrentSelectedEquipment!: Equipment;
  currentSelectedEquipmentReset!: () => void;
  updateSelectedSiteAndUserRole!: (site: string) => void;
  updateSelectedCountryBloc!: (countryBloc: string) => void;
  loadQuickDashboardEquipments!: () => Promise<void>;
  getSelectedSite!: string;
  getSelectedCountryBloc!: string;
  updateQuickDashboardEquipments!: ({
    formattedEquipments,
    quickDashboardEquipments,
  }: {
    formattedEquipments: EquipmentOffShore[];
    quickDashboardEquipments: QuickDashboardEquipment[];
  }) => Promise<void>;
  getQuickDashboardEquipments!: QuickDashboardEquipment[];
  rowData: QuickDashboardEquipment[] = [];
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  defaultColDef = {
    resizable: true,
  };

  columnDefs = [
    {
      headerName: "",
      width: 170,
      cellRendererFramework: QuickDashboardActions,
    },
    {
      headerName: "Condition*",
      field: "condition",
      editable: !this.isUserUnknownOrGuestOrOnshore(),
      cellEditor: "agSelectCellEditor",
      cellRenderer: "QuickDashboardConditions",
      cellEditorParams: {
        values: ["Available", "Downgraded Available", "Unavailable"],
      },
      sortable: true,
      filter: true,
      width: 170,
    },
    {
      headerName: "System*",
      field: "system",
      sortable: true,
      filter: true,
      width: 260,
      cellRenderer: (params: any) => {
        return params.value;
      },
      editable: (params: any) => {
        return params.data.isFreeRow;
      },
    },
    {
      headerName: "Criticality*",
      field: "criticality",
      sortable: true,
      filter: true,
      editable: !this.isUserUnknownOrGuestOrOnshore(),
      width: 180,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: true,
      width: 300,
      editable: (params: any) => {
        return params.data.isFreeRow;
      },
    },
    {
      headerName: "Tag",
      sortable: true,
      filter: true,
      width: 120,
      valueGetter: (params: any) => {
        return (
          params.data.equipmentModelName +
          (params.data.equipmentTag ? "-" + params.data.equipmentTag : "")
        );
      },
      editable: (params: any) => {
        return params.data.isFreeRow;
      },
    },
    {
      headerName: "WO",
      field: "woNumber",
      editable: !this.isUserUnknownOrGuestOrOnshore(),
      sortable: true,
      filter: true,
      width: 185,
      cellRenderer: "QuickDashboardLink",
    },
    {
      headerName: "Comments*",
      field: "comments",
      editable: !this.isUserUnknownOrGuestOrOnshore(),
      sortable: true,
      filter: true,
      cellEditor: "agLargeTextCellEditor",
      width: 350,
      autoHeight: true,
      cellEditorParams: {
        maxLength: 1000,
      },
      cellStyle: {
        "white-space": "pre-wrap",
      },
      cellClassRules: {
        "default-height": (params: any) =>
          params.data.comments === undefined ||
          params.data.comments?.trim() === "",
      },
    },
    {
      headerName: "WO Status",
      field: "woStatus",
      sortable: true,
      filter: true,
      width: 160,
    },
    {
      headerName: "WO Designation",
      field: "woDesignation",
      sortable: true,
      filter: true,
    },
  ];

  async beforeMount(): Promise<void> {
    await this.loadQuickDashboardEquipments();
    this.rowData = _.cloneDeep(this.getQuickDashboardEquipments);
  }

  async created(): Promise<void> {
    this.updateSelectedCountryBloc(this.$route.params.countryBloc.toString());
    this.updateSelectedSiteAndUserRole(this.$route.params.site.toString());
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(): void {
    this.gridApi.setQuickFilter(this.filter);
  }

  onResetData(): void {
    this.rowData = _.cloneDeep(this.getQuickDashboardEquipments);
    this.gridApi.setRowData(this.rowData);
  }

  async onExportData(): Promise<void> {
    this.$q.loading.show();
    try {
      const result = await EquipmentApi.exportEquipmentsOffshore(
        this.getSelectedCountryBloc,
        this.getSelectedSite
      );
      if (isSuccess(result.status)) {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `OneCBM-OffshoreReport-${this.getSelectedCountryBloc}-${
            this.getSelectedSite
          }-${formatDateWithoutHours(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (err: any) {
      this.displayHttpErrorDialog({
        displayHttpErrorDialog: true,
        httpErrorMessage:
          "Export offshore list : " + getMessageByStatusCode(treatErrors(err)),
      });
    }
    this.$q.loading.hide();
  }

  async onUpdate(): Promise<void> {
    this.$q.loading.show();
    let rowData: QuickDashboardEquipment[] = [];
    this.gridApi.forEachNode((node: RowNode) => {
      return rowData.push(node.data);
    });
    const differences = _.differenceWith(
      rowData,
      this.getQuickDashboardEquipments,
      isEqual
    );
    const formattedComments = this.formatDataToUpdate(differences);
    await this.updateQuickDashboardEquipments({
      formattedEquipments: formattedComments,
      quickDashboardEquipments: _.cloneDeep(rowData),
    });
    this.$q.loading.hide();
  }

  formatDataToUpdate(
    quickDashboardData: QuickDashboardEquipment[]
  ): EquipmentOffShore[] {
    const equipmentsToUpdate: EquipmentOffShore[] = [];
    quickDashboardData.forEach(
      (quickDashboardEquipment: QuickDashboardEquipment) => {
        const isFreeRow = quickDashboardEquipment.isFreeRow;
        const woNumberNotNull =
          quickDashboardEquipment.woNumber &&
          quickDashboardEquipment.woNumber.trim() !== "";
        const commentNotNull =
          quickDashboardEquipment.comments &&
          quickDashboardEquipment.comments.trim() !== "";
        if (woNumberNotNull || commentNotNull) {
          const equipmentAlreadyExistIndex = equipmentsToUpdate.findIndex(
            (equipment: EquipmentOffShore) =>
              equipment.equipmentId === quickDashboardEquipment.equipmentId
          );
          const newComment: EquipmentCommentOffshore = {
            id: quickDashboardEquipment.commentId,
            comment: quickDashboardEquipment.comments,
            woNumber:
              quickDashboardEquipment.woNumber === ""
                ? undefined
                : quickDashboardEquipment.woNumber,
            woStatus: quickDashboardEquipment.woStatus,
            woDesignation: quickDashboardEquipment.woDesignation,
            condition: mapConditionStringToEnum(
              quickDashboardEquipment.condition
            ),
          };
          const newFreeComment: EquipmentCommentFreeRow = {
            comment: quickDashboardEquipment.comments,
            woNumber:
              quickDashboardEquipment.woNumber === ""
                ? undefined
                : quickDashboardEquipment.woNumber,
            woStatus: quickDashboardEquipment.woStatus,
            woDesignation: quickDashboardEquipment.woDesignation,
            condition: mapConditionStringToEnum(
              quickDashboardEquipment.condition
            ),
            system: quickDashboardEquipment.system,
            criticality: quickDashboardEquipment.criticality,
            description: quickDashboardEquipment.description,
            tag: quickDashboardEquipment.equipmentTag,
            site: "",
            author: "",
          };
          if (equipmentAlreadyExistIndex !== -1) {
            equipmentsToUpdate[
              equipmentAlreadyExistIndex
            ].equipmentComments.push(newComment);
          } else {
            let newEquipment: EquipmentOffShore;
            if (isFreeRow) {
              newEquipment = {
                equipmentId: undefined,
                system: quickDashboardEquipment.system,
                equipmentModelName: quickDashboardEquipment.equipmentModelName,
                description: quickDashboardEquipment.description,
                equipmentComments: [newComment],
                criticality: quickDashboardEquipment.criticality,
                isFreeRow: isFreeRow,
                equipmentCommentFreeRow: newFreeComment,
                id: quickDashboardEquipment.id,
              };
            } else {
              newEquipment = {
                equipmentId: quickDashboardEquipment.equipmentId,
                system: quickDashboardEquipment.system,
                equipmentModelName: quickDashboardEquipment.equipmentModelName,
                equipmentTag: quickDashboardEquipment.equipmentTag,
                description: quickDashboardEquipment.description,
                equipmentComments: [newComment],
                criticality: quickDashboardEquipment.criticality,
                isFreeRow: isFreeRow,
                equipmentCommentFreeRow: null,
                id: quickDashboardEquipment.id,
              };
            }
            equipmentsToUpdate.push(newEquipment);
          }
        }
      }
    );
    return equipmentsToUpdate;
  }
  addNewEquipmentToTable(isFreeRowBtn: boolean): void {
    this.isFreeRowBtn = isFreeRowBtn;
    let modelName = "";
    let tag = "";

    if (isFreeRowBtn) {
      modelName = "";
      tag = "";
    } else {
      modelName = this.getCurrentSelectedEquipment.equipmentModel.name;
      tag = this.getCurrentSelectedEquipment.tag
        ? this.getCurrentSelectedEquipment.tag
        : "";
    }
    this.gridApi.applyTransaction({
      add: [
        {
          system: isFreeRowBtn
            ? ""
            : this.getCurrentSelectedEquipment.equipmentModel.system,
          description: isFreeRowBtn
            ? ""
            : this.getCurrentSelectedEquipment.name,
          equipmentModelName: modelName,
          equipmentTag: tag,
          equipmentId: isFreeRowBtn ? "" : this.getCurrentSelectedEquipment.id,
          lastStatus: isFreeRowBtn
            ? ""
            : this.getCurrentSelectedEquipment.lastEquipmentStatus?.status,
          criticality: isFreeRowBtn
            ? ""
            : this.getCurrentSelectedEquipment.criticality,
          isFreeRow: isFreeRowBtn,
        },
      ],
      addIndex: 0,
    });

    if (!isFreeRowBtn) {
      this.currentSelectedEquipmentReset();
    }
  }

  onCellValueChanged(params: any): void {
    if (params.oldValue !== params.newValue) {
      const colId = params.column.getId();
      if (colId === "criticality") {
        if (
          params.data.criticality &&
          isNaN(Number(params.data.criticality.toString()))
        ) {
          alert("Criticality must be a numeric value");
          const node = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
          if (node) {
            node.setDataValue(params.column, params.oldValue);
          }
        } else {
          this.setCriticalityForCurrentEquipment(params);
        }
      }
    }
  }

  setCriticalityForCurrentEquipment(params: any): void {
    this.gridApi.forEachNode((node) => {
      if (node.data.equipmentId === params.data.equipmentId) {
        const newData = node.data;
        newData.criticality = params.newValue;
        node.setData(newData);
      }
    });
  }

  isUserUnknownOrGuestOrOnshore(): boolean {
    return (
      isUserUnknown(this.getUserRole) ||
      isUserGuest(this.getUserRole) ||
      isUserOnshore(this.getUserRole)
    );
  }
}
