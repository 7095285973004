import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41af5606"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-between q-px-lg" }
const _hoisted_2 = { class: "row justify-left" }
const _hoisted_3 = {
  key: 1,
  class: "vertical-middle q-mt-md text-h6 text-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn_toggle, {
        modelValue: _ctx.reportingView,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reportingView) = $event)),
        "no-caps": "",
        unelevated: "",
        "toggle-color": "primary",
        class: "toggle-report",
        options: 
          !_ctx.isUserUnknownOrGuest()
            ? [
                { label: 'Weekly report', value: _ctx.ReportingViewType.WEEKLY },
                {
                  label: 'Availability KPI',
                  value: _ctx.ReportingViewType.BARCHART,
                },
                { label: 'Export data', value: _ctx.ReportingViewType.EXPORT },
              ]
            : [
                { label: 'Weekly report', value: _ctx.ReportingViewType.WEEKLY },
                {
                  label: 'Availability KPI',
                  value: _ctx.ReportingViewType.BARCHART,
                },
              ]
        
      }, null, 8 /* PROPS */, ["modelValue", "options"]),
      (_ctx.getReportingView === _ctx.ReportingViewType.WEEKLY)
        ? (_openBlock(), _createBlock(_component_q_select, {
            key: 0,
            modelValue: _ctx.selectedWeek,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedWeek) = $event)),
              _cache[2] || (_cache[2] = (val) => _ctx.weekSelectedHandler(val))
            ],
            class: "q-px-lg",
            options: _ctx.availableWeeks,
            rounded: "",
            outlined: "",
            label: "Select Week",
            "emit-value": "",
            "map-options": "",
            style: {"width":"300px"}
          }, null, 8 /* PROPS */, ["modelValue", "options"]))
        : _createCommentVNode("v-if", true),
      (_ctx.getReportingView === _ctx.ReportingViewType.WEEKLY)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getDateRangeForWeeklyReport), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.getReportingView === _ctx.ReportingViewType.WEEKLY)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          outline: "",
          rounded: "",
          "no-caps": "",
          label: _ctx.expandButtonLabel,
          class: "main-button reset-filters expand-all q-mx-lg",
          onClick: _ctx.openOrCloseAllExpansionItems
        }, null, 8 /* PROPS */, ["label", "onClick"]))
      : _createCommentVNode("v-if", true)
  ]))
}